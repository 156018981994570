import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const coachingDirector = {
    "blockId": uuidv4(),
    "description": "<p>Grow Your Talent: Scale capacity by maximizing the performance and potential of future talent through coaching- creating a space for others to grow, conducting meaningful conversations that are based on inclusive, trustworthy, empowering relationships.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Drives High Velocity Performance "),
                    "subCategory": "Drives High Velocity Performance ",
                    "staticQuestionId": generateStaticQuestionId("Holds individual team members accountable for achieving high quality results"),
                    "HTMLText": "<p>Holds individual team members accountable for achieving high quality results</p>",
                    "isSelected": true,
                    "text": "Holds individual team members accountable for achieving high quality results",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Drives High Velocity Performance "),
                    "subCategory": "Drives High Velocity Performance ",
                    "staticQuestionId": generateStaticQuestionId("Enables team members workload to balance high intensity work with rest and rejuvenation"),
                    "HTMLText": "<p>Enables team members workload to balance high intensity work with rest and rejuvenation</p>",
                    "isSelected": true,
                    "text": "Enables team members workload to balance high intensity work with rest and rejuvenation",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Drives High Velocity Performance "),
                    "subCategory": "Drives High Velocity Performance ",
                    "staticQuestionId": generateStaticQuestionId("Brings indivdual team members along in developing goals and implementation plans"),
                    "HTMLText": "<p>Brings indivdual team members along in developing goals and implementation plans</p>",
                    "isSelected": true,
                    "text": "Brings indivdual team members along in developing goals and implementation plans",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Optimizes Potential Realization"),
                    "subCategory": "Optimizes Potential Realization",
                    "staticQuestionId": generateStaticQuestionId("Inspires team members to raise the performance bar"),
                    "HTMLText": "<p>Inspires team members to raise the performance bar</p>",
                    "isSelected": true,
                    "text": "Inspires team members to raise the performance bar",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Optimizes Potential Realization"),
                    "subCategory": "Optimizes Potential Realization",
                    "staticQuestionId": generateStaticQuestionId("Provides assignments that stretches and grows team members"),
                    "HTMLText": "<p>Provides assignments that stretches and grows team members</p>",
                    "isSelected": true,
                    "text": "Provides assignments that stretches and grows team members",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Optimizes Potential Realization"),
                    "subCategory": "Optimizes Potential Realization",
                    "staticQuestionId": generateStaticQuestionId("Coaches and mentors team members on how to advance in their careers"),
                    "HTMLText": "<p>Coaches and mentors team members on how to advance in their careers</p>",
                    "isSelected": true,
                    "text": "Coaches and mentors team members on how to advance in their careers",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Gives Feedback OR Feed-Forwards (Radical candor)"),
                    "subCategory": "Gives Feedback OR Feed-Forwards (Radical candor)",
                    "staticQuestionId": generateStaticQuestionId("Provides specific and timely feedback to help people grow"),
                    "HTMLText": "<p>Provides specific and timely feedback to help people grow</p>",
                    "isSelected": true,
                    "text": "Provides specific and timely feedback to help people grow",
                    "value": uuidv4()
                },
              
                {
                    "subCategoryId": generateStaticQuestionId("Gives Feedback OR Feed-Forwards (Radical candor)"),
                    "subCategory": "Gives Feedback OR Feed-Forwards (Radical candor)",
                    "staticQuestionId": generateStaticQuestionId("Encourages team members to take initiative in determining how best to get their work done"),
                    "HTMLText": "<p>Encourages team members to take initiative in determining how best to get their work done</p>",
                    "isSelected": true,
                    "text": "Encourages team members to take initiative in determining how best to get their work done",
                    "value": uuidv4()
                },
             
                {
                    "subCategoryId": generateStaticQuestionId("Gives Feedback OR Feed-Forwards (Radical candor)"),
                    "subCategory": "Gives Feedback OR Feed-Forwards (Radical candor)",
                    "staticQuestionId": generateStaticQuestionId("Provides on-going coaching to support personal & professional growth"),
                    "HTMLText": "<p>Provides on-going coaching to support personal & professional growth</p>",
                    "isSelected": true,
                    "text": "Provides on-going coaching to support personal & professional growth",
                    "value": uuidv4()
                },

              
              
               
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Coaching"
}