import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const selfAwarenessDirector = {
    "blockId": uuidv4(),
    "description": "<p>Grow Oneself: Understand the shift in perceptions and accountabilities that are needed for effective management of high performing and engaged individuals and teams. Lead with the heart; understand how EQ and empathy can increase effectiveness and impact.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Seek and Act on Feedback"),
                    "subCategory":"Seek and Act on Feedback",
                    "staticQuestionId": generateStaticQuestionId("Shows awareness of own strengths and development areas"),
                    "HTMLText": "<p>Shows awareness of own strengths and development areas</p>",
                    "isSelected": true,
                    "text": "Shows awareness of own strengths and development areas",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Seek and Act on Feedback"),
                    "subCategory": "Seek and Act on Feedback",
                    "staticQuestionId": generateStaticQuestionId("Actively seeks candid feedback on own performance and personal impact"),
                    "HTMLText": "<p>Actively seeks candid feedback on own performance and personal impact</p>",
                    "isSelected": true,
                    "text": "Actively seeks candid feedback on own performance and personal impact",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Seek and Act on Feedback"),
                    "subCategory": "Seek and Act on Feedback",
                    "staticQuestionId": generateStaticQuestionId("Adapts behavior based on feedback and self-reflection"),
                    "HTMLText": "<p>Adapts behavior based on feedback and self-reflection</p>",
                    "isSelected": true,
                    "text": "Adapts behavior based on feedback and self-reflection",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Self Accountability & Reputation"),
                    "subCategory": "Self Accountability & Reputation",
                    "staticQuestionId": generateStaticQuestionId("Keeps commitments made: is dependable and reliable"),
                    "HTMLText": "<p>Keeps commitments made: is dependable and reliable</p>",
                    "isSelected": true,
                    "text": "Keeps commitments made: is dependable and reliable",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Self Accountability & Reputation"),
                    "subCategory": "Self Accountability & Reputation",
                    "staticQuestionId": generateStaticQuestionId("No hidden agendas: Is honest and straightforward in interactions"),
                    "HTMLText": "<p>No hidden agendas: Is honest and straightforward in interactions</p>",
                    "isSelected": true,
                    "text": "No hidden agendas: Is honest and straightforward in interactions",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Self Accountability & Reputation"),
                    "subCategory": "Self Accountability & Reputation",
                    "staticQuestionId": generateStaticQuestionId("Shares failures & learnings with others to ensure that mistakes are not repeated"),
                    "HTMLText": "<p>Shares failures & learnings with others to ensure that mistakes are not repeated</p>",
                    "isSelected": true,
                    "text": "Shares failures & learnings with others to ensure that mistakes are not repeated",
                    "value": uuidv4()
                },



                {
                    "subCategoryId": generateStaticQuestionId("Practices Courage & Resilience"),
                    "subCategory": "Practices Courage & Resilience",
                    "staticQuestionId": generateStaticQuestionId("Effectively manages emotions in pressure-packed/ frustrating situations"),
                    "HTMLText": "<p>Effectively manages emotions in pressure-packed/ frustrating situations</p>",
                    "isSelected": true,
                    "text": "Effectively manages emotions in pressure-packed/ frustrating situations",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Practices Courage & Resilience"),
                    "subCategory": "Practices Courage & Resilience",
                    "staticQuestionId": generateStaticQuestionId("Persists and shows determination in challenging situations"),
                    "HTMLText": "<p>Persists and shows determination in challenging situations</p>",
                    "isSelected": true,
                    "text": "Persists and shows determination in challenging situations",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Practices Courage & Resilience"),
                    "subCategory": "Practices Courage & Resilience",
                    "staticQuestionId": generateStaticQuestionId("Leads by example and is a role model for Applied Materials values"),
                    "HTMLText": "<p>Leads by example and is a role model for Applied Materials values</p>",
                    "isSelected": true,
                    "text": "Leads by example and is a role model for Applied Materials values",
                    "value": uuidv4()
                },
               
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Self-Awareness"
}