/*eslint-disable*/

//login Page
export const LOGIN_PROCESS_STARTED = "LOGIN_PROCESS_STARTED";
export const LOGIN_PROCESS_SUCCESS = "LOGIN_PROCESS_SUCCESS";
export const LOGIN_PROCESS_FAILED = "LOGIN_PROCESS_FAILED";
export const LOGIN_FORM_CHANGE = "LOGIN_FORM_CHANGE";
//register page
export const REGISTER_PROCESS_STARTED = "REGISTER_PROCESS_STARTED";
export const REGISTER_PROCESS_SUCCESS = "REGISTER_PROCESS_SUCCESS";
export const REGISTER_PROCESS_FAILED = "REGISTER_PROCESS_FAILED";
export const REGISTER_FORM_CHANGE = "REGISTER_FORM_CHANGE";
//verify page
export const VERIFY_PROCESS_STARTED = "VERIFY_PROCESS_STARTED";
export const VERIFY_PROCESS_SUCCESS = "VERIFY_PROCESS_SUCCESS";
export const VERIFY_PROCESS_FAILED = "VERIFY_PROCESS_FAILED";
export const VERIFY_FORM_CHANGE = "VERIFY_FORM_CHANGE";
//user upload
export const HANDLE_BULK_UPLOAD_FILE_DROP = "HANDLE_BULK_UPLOAD_FILE_DROP";
export const TOGGLE_BULK_UPLOAD_MODAL = "TOGGLE_BULK_UPLOAD_MODAL";
export const ADMIN_BULK_UPLOAD_START = "ADMIN_BULK_UPLOAD_START";
export const ADMIN_BULK_UPLOAD_END = "ADMIN_BULK_UPLOAD_END";
export const ADD_JOB_STARTED = "ADD_JOB_STARTED";
export const ADD_JOB_FAILED = "ADD_JOB_FAILED";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
//forgot password
export const FORGOT_PASSWORD_PROCESS_STARTED =
  " FORGOT_PASSWORD_PROCESS_STARTED";
export const FORGOT_PASSWORD_PROCESS_SUCCESS =
  "FORGOT_PASSWORD_PROCESS_SUCCESS";
export const FORGOT_PASSWORD_PROCESS_FAILED = "FORGOT_PASSWORD_PROCESS_FAILED";
export const FORGOT_PASSWORD_FORM_CHANGE = "FORGOT_PASSWORD_FORM_CHANGE";

export const CHANGE_PASSWORD_FORM_CHANGE = "CHANGE_PASSWORD_FORM_CHANGE";
export const CHANGE_PASSWORD_PROCESS_STARTED =
  "CHANGE_PASSWORD_PROCESS_STARTED";
export const CHANGE_PASSWORD_PROCESS_SUCCESS =
  "CHANGE_PASSWORD_PROCESS_SUCCESS";
export const CHANGE_PASSWORD_PROCESS_FAILED = "CHANGE_PASSWORD_PROCESS_FAILED";
export const CHANGE_PASSWORD_PROCESS_CLEAR = "CHANGE_PASSWORD_PROCESS_CLEAR";

export const RESET_PASSWORD_PROCESS_STARTED = "RESET_PASSWORD_PROCESS_STARTED";
export const RESET_PASSWORD_PROCESS_SUCCESS = "RESET_PASSWORD_PROCESS_SUCCESS";
export const RESET_PASSWORD_PROCESS_FAILED = "RESET_PASSWORD_PROCESS_FAILED";
export const RESET_PASSWORD_FORM_CHANGE = "RESET_PASSWORD_FORM_CHANGE";

export const RESPONSES_PROCESS_STARTED = "RESPONSES_PROCESS_STARTED";
export const RESPONSES_PROCESS_SUCCESS = "RESPONSES_PROCESS_SUCCESS";
export const RESPONSES_PROCESS_FAILED = "RESPONSES_PROCESS_FAILED";

/* homePageAction / Reducer Constansts */
export const FETCH_ORGANIZATION_LIST_STARTED =
  "FETCH_ORGANIZATION_LIST_STARTED";
export const FETCH_ORGANIZATION_LIST_SUCCESS =
  "FETCH_ORGANIZATION_LIST_SUCCESS";
export const FETCH_ORGANIZATION_LIST_FAILED = "FETCH_ORGANIZATION_LIST_FAILED";

export const ADD_ORGANIZATION_STARTED = "ADD_ORGANIZATION_STARTED";
export const ADD_ORGANIZATION_SUCCESS = "ADD_ORGANIZATION_SUCCESS";
export const ADD_ORGANIZATION_FAILED = "ADD_ORGANIZATION_FAILED";
export const INPUT_ADD_ORGANIZATION = "INPUT_ADD_ORGANIZATION";

// Fetch organzation profile details
export const GET_ORGANZATION_PROFILE_STARTED =
  "GET_ORGANZATION_PROFILE_STARTED";
export const GET_ORGANZATION_PROFILE_SUCCESS =
  "GET_ORGANZATION_PROFILE_SUCCESS";
export const GET_ORGANZATION_PROFILE_FAILED = "GET_ORGANZATION_PROFILE_FAILED";

//Users page
export const FETCH_USERS_LIST_STARTED = "FETCH_USERS_LIST_STARTED";
export const FETCH_USERS_LIST_SUCCESS = "FETCH_USERS_LIST_SUCCESS";
export const FETCH_USERS_LIST_FAILED = "FETCH_USERS_LIST_FAILED";
export const FETCH_HRIS_LIST_STARTED = "FETCH_HRIS_LIST_STARTED";
export const FETCH_HRIS_LIST_SUCCESS = "FETCH_HRIS_LIST_SUCCESS";
export const FETCH_HRIS_LIST_FAILED = "FETCH_HRIS_LIST_FAILED";
export const FETCH_HRIS_HIERARCHY_STARTED = "FETCH_HRIS_HIERARCHY_STARTED";
export const FETCH_HRIS_HIERARCHY_SUCCESS = "FETCH_HRIS_HIERARCHY_SUCCESS";
export const FETCH_HRIS_HIERARCHY_FAILED = "FETCH_HRIS_HIERARCHY_FAILED";

export const CHANGE_ACTIVE_FL_NAME_STARTED = "CHANGE_ACTIVE_FL_NAME_STARTED";
export const CHANGE_ACTIVE_FL_NAME_SUCCESS = "CHANGE_ACTIVE_FL_NAME_SUCCESS";
export const CHANGE_ACTIVE_FL_NAME_FAILED = "CHANGE_ACTIVE_FL_NAME_FAILED";
export const CHANGE_ACTIVE_FL_NAME_FORM_CHANGE =
  "CHANGE_ACTIVE_FL_NAME_FORM_CHANGE";
export const CHANGE_ACTIVE_FL_NAME_CLEAR = "CHANGE_ACTIVE_FL_NAME_CLEAR";

export const CHANGE_ACTIVE_USER_ROLE_STARTED =
  "CHANGE_ACTIVE_USER_ROLE_STARTED";
export const CHANGE_ACTIVE_USER_ROLE_SUCCESS =
  "CHANGE_ACTIVE_USER_ROLE_SUCCESS";
export const CHANGE_ACTIVE_USER_ROLE_FAILED = "CHANGE_ACTIVE_USER_ROLE_FAILED";

export const DELETE_ACTIVE_USER_STARTED = "DELETE_ACTIVE_USER_STARTED";
export const DELETE_ACTIVE_USER_SUCCESS = "DELETE_ACTIVE_USER_SUCCESS";
export const DELETE_ACTIVE_USER_FAILED = "DELETE_ACTIVE_USER_FAILED";

export const SHOW_INVITE_BOX = "SHOW_INVITE_BOX";
export const INPUT_ADD_USER = "INPUT_ADD_USER";

export const INVITE_USER_STARTED = "INVITE_USER_STARTED";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILED = "INVITE_USER_FAILED";

//
export const INVITE_ADMIN_USER_STARTED = "INVITE_ADMIN_USER_STARTED";
export const INVITE_ADMIN_USER_SUCCESS = "INVITE_ADMIN_USER_SUCCESS";
export const INVITE_ADMIN_USER_FAILED = "INVITE_ADMIN_USER_FAILED";
//
//Get user profile
export const GET_USER_PROFILE_STARTED = "GET_USER_PROFILE_STARTED";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";
//Surveys
export const FETCH_SURVEYS_LIST_SUCCESS = "FETCH_SURVEYS_LIST_SUCCESS";
export const FETCH_SURVEYS_LIST_STARTED = "FETCH_SURVEYS_LIST_STARTED";
export const FETCH_SURVEYS_LIST_FAILED = "FETCH_SURVEYS_LIST_FAILED";

export const FILE_UPLOAD_STARTED = "FILE_UPLOAD_STARTED";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";
export const FILE_SELECTED = "FILE_SELECTED";

export const DELETE_SURVEY_STARTED = "DELETE_SURVEY_STARTED";
export const DELETE_SURVEY_SUCCESS = "DELETE_SURVEY_SUCCESS";
export const DELETE_SURVEY_FAILED = "DELETE_SURVEY_FAILED";

export const PARSE_CSV_STARTED = "PARSE_CSV_STARTED";
export const PARSE_CSV_SUCCESS = "PARSE_CSV_SUCCESS";
export const PARSE_CSV_FAILED = "PARSE_CSV_FAILED";

export const SELECTED_FACETS_CHANGE = "SELECTED_FACETS_CHANGE";
export const FACET_GROUP_CHANGE = "FACET_GROUP_CHANGE";

export const FETCH_SURVEY_TABLE_SNAPSHOT_STARTED =
  "FETCH_SURVEY_TABLE_SNAPSHOT_STARTED";
export const FETCH_SURVEY_TABLE_SNAPSHOT_SUCCESS =
  "FETCH_SURVEY_TABLE_SNAPSHOT_SUCCESS";
export const FETCH_SURVEY_TABLE_SNAPSHOT_FAILED =
  "FETCH_SURVEY_TABLE_SNAPSHOT_FAILED ";
export const UPDATE_COMPARISON_PIVOT_STATE_SUCCESS =
  "UPDATE_COMPARISON_PIVOT_STATE_SUCCESS";
export const UPDATE_COMPARISON_PIVOT_STATE_START =
  "UPDATE_COMPARISON_PIVOT_STATE_START";
export const UPDATE_COMPARISON_PIVOT_STATE_FAIL =
  "UPDATE_COMPARISON_PIVOT_STATE_FAIL";

//Delete Organization
export const DELETE_ORGANIZATION_STARTED = "DELETE_ORGANIZATION_STARTED";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAILED = "DELETE_ORGANIZATION_FAILED";

//Get filter aggregtion
export const GET_FILTER_AGGREGATION_STARTED = "GET_FILTER_AGGREGATION_STARTED";
export const GET_FILTER_AGGREGATION_SUCCESS = "GET_FILTER_AGGREGATION_SUCCESS";
export const GET_FILTER_AGGREGATION_FAILED = "GET_FILTER_AGGREGATION_FAILED";

//Fetch survey table data
export const FETCH_SURVEY_DATA_STARTED = "FETCH_SURVEY_DATA_STARTED";
export const FETCH_SURVEY_DATA_SUCCESS = "FETCH_SURVEY_DATA_SUCCESS";
export const FETCH_SURVEY_DATA_FAILED = "FETCH_SURVEY_DATA_FAILED";

//Fetch products details on click
export const PRODUCT_CHANGE = "PRODUCT_CHANGE";

export const SHOW_MODAL = "SHOW_MODAL";
export const POPUPMENU_VISIBILITY = "POPUPMENU_VISIBILITY";

export const UPDATE_CONTACT_FORM_CHECK_BOX = "UPDATE_CONTACT_FORM_CHECK_BOX";
export const UPDATE_CONTACT_FORM_FIELD = "UPDATE_CONTACT_FORM_FIELD";
export const SUBMIT_CONTACT_FORM_START = "SUBMIT_CONTACT_FORM_START";
export const SUBMIT_CONTACT_FORM_FAIL = "SUBMIT_CONTACT_FORM_FAIL";
export const SUBMIT_CONTACT_FORM_SUCCESS = "SUBMIT_CONTACT_FORM_SUCCESS";

export const SELECTED_FACETS_INIT_STATE = "SELECTED_FACETS_INIT_STATE";

export const TOGGLE_SURVEY_NAVBAR = "TOGGLE_SURVEY_NAVBAR";

export const FETCH_EXECUTIVE_OVERVIEW_STARTED =
  "FETCH_EXECUTIVE_OVERVIEW_STARTED";
export const FETCH_EXECUTIVE_OVERVIEW_FAILED =
  "FETCH_EXECUTIVE_OVERVIEW_FAILED";
export const FETCH_EXECUTIVE_OVERVIEW_SUCCESS =
  "FETCH_EXECUTIVE_OVERVIEW_SUCCESS";
export const UPDATE_CAROUSEL_INDEX = "UPDATE_CAROUSEL_INDEX";
export const UPADTE_CAROUSEL_WIDTH = "UPADTE_CAROUSEL_WIDTH";

export const FETCH_SCORES_STARTED = "FETCH_SCORES_STARTED";
export const FETCH_SCORES_SUCCESS = "FETCH_SCORES_SUCCESS";
export const FETCH_SCORES_FAIL = "FETCH_SCORES_FAIL";

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_NEXT = "FETCH_USERS_NEXT";
export const FETCH_USERS_PREV = "FETCH_USERS_PREV";

export const FETCH_USERS_NEXT_LIST_SUCCESS = "FETCH_USERS_NEXT_LIST_SUCCESS";
export const FETCH_USERS_PREV_LIST_SUCCESS = "FETCH_USERS_PREV_LIST_SUCCESS";
export const FETCH_USERS_NEXT_LIST_FAILED = "FETCH_USERS_NEXT_LIST_FAILED";
export const FETCH_USERS_PREV_LIST_FAILED = "FETCH_USERS_PREV_LIST_FAILED";

export const FETCH_DRIVERS_DATA_START = "FETCH_DRIVERS_DATA_START";
export const FETCH_DRIVERS_DATA_FAIL = "FETCH_DRIVERS_DATA_FAIL";
export const FETCH_DRIVERS_DATA_SUCCESS = "FETCH_DRIVERS_DATA_SUCCESS";
export const DRIVERS_SCATTER_CHANGE_OPACITY = "DRIVERS_SCATTER_CHANGE_OPACITY";

export const FETCH_OUTLIER_STARTED = "FETCH_OUTLIER_STARTED";
export const FETCH_OUTLIER_SUCCESS = "FETCH_OUTLIER_SUCCESS";
export const FETCH_OUTLIER_FAIL = "FETCH_OUTLIER_FAIL";
export const CHANGE_OUTCOME_VARIABLE = "CHANGE_OUTCOME_VARIABLE";

export const FETCH_SPREAD_ANALYSIS_STARTED = "FETCH_SPREAD_ANALYSIS_STARTED";
export const FETCH_SPREAD_ANALYSIS_SUCCESS = "FETCH_SPREAD_ANALYSIS_SUCCESS";
export const FETCH_SPREAD_ANALYSIS_FAIL = "FETCH_SPREAD_ANALYSIS_FAIL";

export const UPDATE_FILTER_VISIBILITY = "UPDATE_FILTER_VISIBILITY";

export const SET_PROJECT_CONFIG = "SET_PROJECT_CONFIG";
export const GET_PROJECT_CONFIG_START = "GET_PROJECT_CONFIG_START";
export const GET_PROJECT_CONFIG_SUCESS = "GET_PROJECT_CONFIG_SUCESS";
export const GET_PROJECT_CONFIG_FAIL = "GET_PROJECT_CONFIG_FAIL";

export const UPDATE_SURVEY_ANSWER = "UPDATE_SURVEY_ANSWER";
export const FETCH_PROJECT_START = "FETCH_PROJECT_START";
export const FETCH_PROJECT_FAIL = "FETCH_PROJECT_FAIL";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";

export const TRANSLATE_START = "TRANSLATE_START";
export const TRANSLATE_FAIL = "TRANSLATE_FAIL";
export const TRANSLATE_SUCCESS = "TRANSLATE_SUCCESS";

export const TRANSLATE_PREVIEW_START = "TRANSLATE_PREVIEW_START";
export const TRANSLATE_PREVIEW_FAIL = "TRANSLATE_PREVIEW_FAIL";
export const TRANSLATE_PREVIEW_SUCCESS = "TRANSLATE_PREVIEW_SUCCESS";

export const FETCH_PROJECTS_START = "FETCH_PROJECTS_START";
export const FETCH_PROJECTS_FAIL = "FETCH_PROJECTS_FAIL";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";

export const SAVE_PROJECT_START = "SAVE_PROJECT_START";
export const SAVE_PROJECT_FAIL = "SAVE_PROJECT_FAIL";
export const SAVE_PROJECT_SUCCESS = "SAVE_PROJECT_SUCCESS";

export const CREATE_PROJECT_START = "CREATE_PROJECT_START";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";
export const SET_ORGANIZATION_PROJECT_COUNT = "SET_ORGANIZATION_PROJECT_COUNT";

export const SAVE_QUESTIONSET_START = "SAVE_QUESTIONSET_START";
export const SAVE_QUESTIONSET_SUCCESS = "SAVE_QUESTIONSET_SUCCESS";
export const SAVE_QUESTIONSET_FAIL = "SAVE_QUESTIONSET_FAIL";

export const COPY_SURVEY_START = "COPY_SURVEY_START";
export const COPY_SURVEY_SUCCESS = "COPY_SURVEY_SUCCESS";
export const COPY_SURVEY_FAIL = "COPY_SURVEY_FAIL";

//AttendSurvey Route
export const GET_ATTENT_SURVEY_DATA_START = "GET_ATTENT_SURVEY_DATA_START";
export const GET_ATTENT_SURVEY_DATA_SUCCESS = "GET_ATTENT_SURVEY_DATA_SUCCESS";
export const GET_ATTENT_SURVEY_DATA_FAIL = "GET_ATTENT_SURVEY_DATA_FAIL";

export const ATTENT_SURVEY_UPDATE_ANSWER = "ATTENT_SURVEY_UPDATE_ANSWER";

export const SUBMIT_SURVEY_DATA_START = "SUBMIT_SURVEY_DATA_START";
export const SUBMIT_SURVEY_DATA_SUCCESS = "SUBMIT_SURVEY_DATA_SUCCESS";
export const SUBMIT_SURVEY_DATA_FAIL = "SUBMIT_SURVEY_DATA_FAIL";

export const UPDATE_SURVEY_PROCESS_LIST = "UPDATE_SURVEY_PROCESS_LIST";

//edit project route
export const EDIT_PROJECT_PUBLISH_START = "EDIT_PROJECT_PUBLISH_START";
export const EDIT_PROJECT_PUBLISH_SUCCESS = "EDIT_PROJECT_PUBLISH_SUCCESS";
export const EDIT_PROJECT_PUBLISH_FAIL = "EDIT_PROJECT_PUBLISH_FAIL";

//rename project route
export const RENAME_PROJECT_START = "RENAME_PROJECT_START";
export const RENAME_PROJECT_SUCCESS = "RENAME_PROJECT_SUCCESS";
export const RENAME_PROJECT_FAIL = "RENAME_PROJECT_FAIL";

//rename organization route
export const RENAME_ORG_START = "RENAME_ORG_START";
export const RENAME_ORG_SUCCESS = "RENAME_ORG_SUCCESS";
export const RENAME_ORG_FAIL = "RENAME_ORG_FAIL";

export const SUBMIT_ANSWERSET_START = "SUBMIT_ANSWERSET_START";
export const SUBMIT_ANSWERSET_SUCCESS = "SUBMIT_ANSWERSET_SUCCESS";
export const SUBMIT_ANSWERSET_FAIL = "SUBMIT_ANSWERSET_FAIL";

export const CHANGE_SURVEY_LANGUAGE = "CHANGE_SURVEY_LANGUAGE";
export const CHANGE_SURVEY_LANGUAGE_PREVIEW = "CHANGE_SURVEY_LANGUAGE_PREVIEW";


export const DISTRIBUTE_SEND_EMAIL_START = "DISTRIBUTE_SEND_EMAIL_START";
export const DISTRIBUTE_SEND_EMAIL_SUCCESS = "DISTRIBUTE_SEND_EMAIL_SUCCESS";
export const DISTRIBUTE_SEND_EMAIL_FAIL = "DISTRIBUTE_SEND_EMAIL_FAIL";
export const DISTRIBUTE_EMAIL_UPDATE_FORM = "DISTRIBUTE_EMAIL_UPDATE_FORM";

export const GET_DISTRIBUTION_DATA_START = "GET_DISTRIBUTION_DATA_START";
export const GET_DISTRIBUTION_DATA_SUCCESS = "GET_DISTRIBUTION_DATA_SUCCESS";
export const GET_DISTRIBUTION_DATA_FAIL = "GET_DISTRIBUTION_DATA_FAIL";

export const GET_PARTICIPATON_RATE_START = "GET_PARTICIPATION_RATE_START";
export const GET_PARTICIPATON_RATE_SUCCESS = "GET_PARTICIPATION_RATE_SUCCESS";
export const GET_PARTICIPATON_RATE_FAIL = "GET_PARTICIPATION_RATE_FAIL";

export const SURVEY_REPORT_DOWNLOAD_START = "SURVEY_REPORT_DOWNLOAD_START";
export const SURVEY_REPORT_DOWNLOAD_SUCCESS = "SURVEY_REPORT_DOWNLOAD_SUCCESS";
export const SURVEY_REPORT_DOWNLOAD_FAIL = "SURVEY_REPORT_DOWNLOAD_FAIL";

export const SURVEY_PARTICIPANT_DOWNLOAD_START = "SURVEY_PARTICIPANT_DOWNLOAD_START";
export const SURVEY_PARTICIPANT_DOWNLOAD_SUCCESS = "SURVEY_PARTICIPANT_DOWNLOAD_SUCCESS";
export const SURVEY_PARTICIPANT_DOWNLOAD_FAIL = "SURVEY_PARTICIPANT_DOWNLOAD_FAIL";

export const FETCH_PARTICIPATION_DATA_START = "FETCH_PARTICIPATION_DATA_START";
export const FETCH_PARTICIPATION_DATA_SUCCESS =
  "FETCH_PARTICIPATION_DATA_SUCCESS";
export const FETCH_PARTICIPATION_DATA_FAIL = "FETCH_PARTICIPATION_DATA_FAIL";

export const DELETE_PROJECT_START = "DELETE_PROJECT_START";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

export const DELETE_ORGANISATION_PROJECT_START = "DELETE_ORGANISATION_PROJECT_START";
export const DELETE_ORGANISATION_PROJECT_SUCCESS = "DELETE_ORGANISATION_PROJECT_SUCCESS";
export const DELETE_ORGANISATION_PROJECT_FAIL = "DELETE_ORGANISATION_PROJECT_FAIL";

/*   NLP   */
export const FETCH_COMMENTS_START = "FETCH_COMMENTS_START";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAIL = "FETCH_COMMENTS_FAIL";

export const MAP_SENTIMENT_TO_FILTER = "MAP_SENTIMENT_TO_FILTER";
export const FETCH_SENTIMENT_START = "FETCH_SENTIMENT_START";
export const FETCH_SENTIMENT_SUCCESS = "FETCH_SENTIMENT_SUCCESS";
export const FETCH_SENTIMENT_FAIL = "FETCH_SENTIMENT_FAIL";

export const FETCH_SENTIMENT_TOPICS_START = "FETCH_SENTIMENT_TOPICS_START";
export const FETCH_SENTIMENT_TOPICS_SUCCESS = "FETCH_SENTIMENT_TOPICS_SUCCESS";
export const FETCH_SENTIMENT_TOPICS_FAIL = "FETCH_SENTIMENT_TOPICS_FAIL";

export const FETCH_TOPICS_START = "FETCH_TOPICS_START";
export const FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS";
export const FETCH_TOPICS_FAIL = "FETCH_TOPICS_FAIL";
export const BATCH_ID_FOR_UPLOAD = "BATCH_ID_FOR_UPLOAD";

export const SET_TOPIC_REPORT_TYPE = "SET_TOPIC_REPORT_TYPE";

export const FETCH_TOPICS_RELATION_START = "FETCH_TOPICS_RELATION_START";
export const FETCH_TOPICS_RELATION_SUCCESS = "FETCH_TOPICS_RELATION_SUCCESS";
export const FETCH_TOPICS_RELATION_FAIL = "FETCH_TOPICS_RELATION_FAIL";

//for nlp insight tab
export const FETCH_NLP_INSIGHTS_START = "FETCH_NLP_INSIGHTS_START";
export const FETCH_NLP_INSIGHTS_SUCCESS = "FETCH_NLP_INSIGHTS_SUCCESS";
export const FETCH_NLP_INSIGHTS_FAIL = "FETCH_NLP_INSIGHTS_FAIL";

export const FETCH_NUMERIC_INSIGHTS_START = "FETCH_NUMERIC_INSIGHTS_START";
export const FETCH_NUMERIC_INSIGHTS_SUCCESS = "FETCH_NUMERIC_INSIGHTS_SUCCESS";
export const FETCH_NUMERIC_INSIGHTS_FAIL = "FETCH_NUMERIC_INSIGHTS_FAIL";

//for nlp comparison tab
export const FETCH_NLP_TOPICSDATA_START = "FETCH_NLP_TOPICSDATA_START";
export const FETCH_NLP_TOPICSDATA_SUCCESS = "FETCH_NLP_TOPICSDATA_SUCCESS";
export const FETCH_NLP_TOPICSDATA_FAIL = "FETCH_NLP_TOPICSDATA_FAIL";

export const FETCH_DEMOGRAPHIC_SNAPSHOT_START =
  "FETCH_DEMOGRAPHIC_SNAPSHOT_START";
export const FETCH_DEMOGRAPHIC_SNAPSHOT_SUCCESS =
  "FETCH_DEMOGRAPHIC_SNAPSHOT_SUCCESS";
export const FETCH_DEMOGRAPHIC_SNAPSHOT_FAIL =
  "FETCH_DEMOGRAPHIC_SNAPSHOT_FAIL";
export const COMPARISONTAB_CHANGE_TOPICS_DROPDOWN =
  "COMPARISONTAB_CHANGE_TOPICS_DROPDOWN";
export const COMPARISONTAB_UPDATE_COLUMNS_LIST =
  "COMPARISONTAB_UPDATE_COLUMNS_LIST";
export const COMPARISONTAB_UPDATE_NUMERIC_COLUMNS_LIST =
  "COMPARISONTAB_UPDATE_NUMERIC_COLUMNS_LIST";
export const COMPARISONTAB_DESELECT_NUMERIC_COLUMNS_LIST =
  "COMPARISONTAB_DESELECT_NUMERIC_COLUMNS_LIST";
export const COMPARISONTAB_DESELECT_QUESTIONS_COLUMNS_LIST = "COMPARISONTAB_DESELECT_QUESTIONS_COLUMNS_LIST";
export const FETCH_LOGS_LIST_STARTED = "FETCH_LOGS_LIST_STARTED";
export const FETCH_LOGS_LIST_SUCCESS = "FETCH_LOGS_LIST_SUCCESS";
export const FETCH_LOGS_LIST_FAILED = "FETCH_LOGS_LIST_FAILED";

export const FETCH_KEYWORD_START = "FETCH_KEYWORD_START";
export const FETCH_KEYWORD_SUCCESS = "FETCH_KEYWORD_SUCCESS";
export const FETCH_KEYWORD_FAIL = "FETCH_KEYWORD_FAIL";

// export const GET_USER_PROFILE_STARTED = 'GET_USER_PROFILE_STARTED';
// export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
// export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

export const UPLOAD_COMMENTS_START = "UPLOAD_COMMENTS_START";
export const UPLOAD_COMMENTS_SUCCESS = "UPLOAD_COMMENTS_SUCCESS";
export const UPLOAD_COMMENTS_FAIL = "UPLOAD_COMMENTS_FAIL";
export const UPDATE_FIELDS = "UPDATE_FIELDS";

export const UPLOAD_TOPIC_FILE_START = "UPLOAD_TOPIC_FILE_START";
export const UPLOAD_TOPIC_FILE_SUCCESS = "UPLOAD_TOPIC_FILE_SUCCESS";
export const UPLOAD_TOPIC_FILE_FAIL = "UPLOAD_TOPIC_FILE_FAIL";
export const UPLOAD_HRIS_FILE_START = "UPLOAD_HRIS_FILE_START";
export const UPLOAD_HRIS_FILE_SUCCESS = "UPLOAD_HRIS_FILE_SUCCESS";
export const UPLOAD_HRIS_FILE_FAIL = "UPLOAD_HRIS_FILE_FAIL";

export const FETCH_BATCH_IDS_STARTED = "FETCH_BATCH_IDS_STARTED";
export const FETCH_BATCH_IDS_SUCCESS = "FETCH_BATCH_IDS_SUCCESS";
export const FETCH_BATCH_IDS_FAIL = "FETCH_BATCH_IDS_FAIL";

export const FETCH_FILTERS_STARTED = "FETCH_FILTERS_STARTED";
export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";
export const FETCH_FILTERS_FAIL = "FETCH_FILTERS_FAIL";
export const UPDATE_ACTIVE_FACET = "UPDATE_ACTIVE_FACET";
export const UPDATE_FACET_LIST = "UPDATE_FACET_LIST";
export const RESET_FACET_LIST = "RESET_FACET_LIST";

export const PROCESS_FILES_START = "PROCESS_FILES_START";
export const PROCESS_FILES_SUCCESS = "PROCESS_FILES_SUCCESS";
export const PROCESS_FILES_FAIL = "PROCESS_FILES_FAIL";
export const CLEAR_FILES_DATA = "CLEAR_FILES_DATA";

export const REPROCESS_FILES_START = "REPROCESS_FILES_START";
export const REPROCESS_FILES_SUCCESS = "REPROCESS_FILES_SUCCESS";
export const REPROCESS_FILES_FAIL = "REPROCESS_FILES_FAIL";

export const DOWNLOAD_DEFAULT_TOPICS_SUCCESS =
  "DOWNLOAD_DEFAULT_TOPICS_SUCCESS";
export const DOWNLOAD_DEFAULT_TOPICS_FAIL = "DOWNLOAD_DEFAULT_TOPICS_FAIL";
export const DOWNLOAD_DEFAULT_TOPICS_START = "DOWNLOAD_DEFAULT_TOPICS_START";

export const GET_BATCH_IDS_STARTED = "GET_BATCH_IDS_STARTED";
export const GET_BATCH_IDS_SUCCESS = "GET_BATCH_IDS_SUCCESS";
export const GET_BATCH_IDS_FAIL = "GET_BATCH_IDS_FAIL";

export const DOWNLOAD_SAMPLE_COMMENT_SUCCESS =
  "DOWNLOAD_SAMPLE_COMMENT_SUCCESS";
export const DOWNLOAD_SAMPLE_COMMENT_FAIL = "DOWNLOAD_SAMPLE_COMMENT_FAIL";
export const DOWNLOAD_SAMPLE_COMMENT_START = "DOWNLOAD_SAMPLE_COMMENT_START";

export const DOWNLOAD_USER_COMMENT_SUCCESS = "DOWNLOAD_USER_COMMENT_SUCCESS";
export const DOWNLOAD_USER_COMMENT_FAIL = "DOWNLOAD_USER_COMMENT_FAIL";
export const DOWNLOAD_USER_COMMENT_START = "DOWNLOAD_USER_COMMENT_START";

export const APPROVE_JOB_REQUEST_START = "APPROVE_JOB_REQUEST_START";
export const APPROVE_JOB_REQUEST_FAIL = "APPROVE_JOB_REQUEST_FAIL";
export const APPROVE_JOB_REQUEST_SUCCESS = "APPROVE_JOB_REQUEST_SUCCESS";

export const BATCH_APPROVE_JOBS_START = "BATCH_APPROVE_JOBS_START";
export const BATCH_APPROVE_JOBS_FAIL = "BATCH_APPROVE_JOBS_FAIL";
export const BATCH_APPROVE_JOBS_SUCCESS = "BATCH_APPROVE_JOBS_SUCCESS";

export const BATCH_DELETE_JOBS_START = "BATCH_DELETE_JOBS_START";
export const BATCH_DELETE_JOBS_FAIL = "BATCH_DELETE_JOBS_FAIL";
export const BATCH_DELETE_JOBS_SUCCESS = "BATCH_DELETE_JOBS_SUCCESS";

export const FETCH_TOPICS_REPRESENTATION_START =
  "FETCH_TOPICS_REPRESENTATION_START";
export const FETCH_TOPICS_REPRESENTATION_SUCCESS =
  "FETCH_TOPICS_REPRESENTATION_SUCCESS";
export const FETCH_TOPICS_REPRESENTATION_FAIL =
  "FETCH_TOPICS_REPRESENTATION_FAIL";

export const FETCH_TOPICS_WORDCLOUD_START = "FETCH_TOPICS_WORDCLOUD_START";
export const FETCH_TOPICS_WORDCLOUD_SUCCESS = "FETCH_TOPICS_WORDCLOUD_SUCCESS";
export const FETCH_TOPICS_WORDCLOUD_FAIL = "FETCH_TOPICS_WORDCLOUD_FAIL";

export const UPLOAD_REPROCESS_START = "UPLOAD_REPROCESS_START";
export const UPLOAD_REPROCESS_SUCCESS = "UPLOAD_REPROCESS_SUCCESS";
export const UPLOAD_REPROCESS_FAIL = "UPLOAD_REPROCESS_FAIL";

export const GET_CSV_COLUMN_START = "GET_CSV_COLUMN_START";
export const GET_CSV_COLUMN_SUCCESS = "GET_CSV_COLUMN_SUCCESS";
export const GET_CSV_COLUMN_FAIL = "GET_CSV_COLUMN_FAIL";

// NLP route

export const FETCH_NLP_BATCHIDS_START = "FETCH_NLP_BATCHIDS_START";
export const FETCH_NLP_BATCHIDS_SUCCESS = "FETCH_NLP_BATCHIDS_SUCCESS";
export const FETCH_NLP_BATCHIDS_FAIL = "FETCH_NLP_BATCHIDS_FAIL";

export const FETCH_BATCH_QUESTIONS_START = "FETCH_BATCH_QUESTIONS_START";
export const FETCH_BATCH_QUESTIONS_SUCCESS = "FETCH_BATCH_QUESTIONS_SUCCESS";
export const FETCH_BATCH_QUESTIONS_FAIL = "FETCH_BATCH_QUESTIONS_FAIL";

export const FETCH_NUMERIC_DEMOGRAPHICS_START =
  "FETCH_NUMERIC_DEMOGRAPHICS_START";
export const FETCH_NUMERIC_DEMOGRAPHICS_SUCCESS =
  "FETCH_NUMERIC_DEMOGRAPHICS_SUCCESS";
export const FETCH_NUMERIC_DEMOGRAPHICS_FAIL =
  "FETCH_NUMERIC_DEMOGRAPHICS_FAIL";

export const FETCH_NLP_FILTER_START = "FETCH_NLP_FILTER_START";
export const FETCH_NLP_FILTER_SUCCESS = "FETCH_NLP_FILTER_SUCCESS";
export const FETCH_NLP_FILTER_FAIL = "FETCH_NLP_FILTER_FAIL";

export const FETCH_COMMENT_STATISTICS = "FETCH_COMMENT_STATISTICS";
export const FETCH_COMMENT_STATISTICS_SUCCESS =
  "FETCH_COMMENT_STATISTICS_SUCCESS";
export const FETCH_COMMENT_STATISTICS_FAIL = "FETCH_COMMENT_STATISTICS_FAIL";

export const FETCH_NLP_COMMENT_COUNT_START = "FETCH_NLP_COMMENT_COUNT_START";
export const FETCH_NLP_COMMENT_COUNT_SUCCESS =
  "FETCH_NLP_COMMENT_COUNT_SUCCESS";
export const FETCH_NLP_COMMENT_COUNT_FAIL = "FETCH_NLP_COMMENT_COUNT_FAIL";

export const UPDATE_NLP_FACET_GROUP = "UPDATE_NLP_FACET_GROUP";
export const UPDATE_NLP_FACET_GROUP_ITEM = "UPDATE_NLP_FACET_GROUP_ITEM";
export const ADD_ADDITIONAL_FILTERS = "ADD_ADDITIONAL_FILTERS";

//Role page
export const FETCH_ROLES_LIST_STARTED = "FETCH_ROLES_LIST_STARTED";
export const FETCH_ROLES_LIST_SUCCESS = "FETCH_ROLES_LIST_SUCCESS";
export const FETCH_ROLES_LIST_FAILED = "FETCH_ROLES_LIST_FAILED";

export const CREATE_ROLE_STARTED = "CREATE_ROLE_STARTED";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILED = "CREATE_ROLE_FAILED";

export const DELETE_ROLE_STARTED = "DELETE_ROLE_STARTED";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILED = "DELETE_ROLE_FAILED";

//admin (userpage)
export const ADD_ADMIN_STARTED = "ADD_ADMIN_STARTED";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILED = "ADD_ADMIN_FAILED";

export const FETCH_USERS_ADMIN_LIST_STARTED = "FETCH_USERS_ADMIN_LIST_STARTED";
export const FETCH_USERS_ADMIN_LIST_SUCCESS = "FETCH_USERS_ADMIN_LIST_SUCCESS";
export const FETCH_USERS_ADMIN_LIST_FAILED = "FETCH_USERS_ADMIN_LIST_FAILED";

//USER (userpage)
export const ADD_TEMPLATE_STARTED = "ADD_TEMPLATE_STARTED";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILED = "ADD_TEMPLATE_FAILED";

export const FETCH_ADMIN_LIST_STARTED = "FETCH_ADMIN_LIST_STARTED";
export const FETCH_ADMIN_LIST_SUCCESS = "FETCH_ADMIN_LIST_SUCCESS";
export const FETCH_ADMIN_LIST_FAILED = "FETCH_ADMIN_LIST_FAILED";
//

export const FETCH_TEMPLATE_LIST_STARTED = "FETCH_TEMPLATE_LIST_STARTED";
export const FETCH_TEMPLATE_LIST_SUCCESS = "FETCH_TEMPLATE_LIST_SUCCESS";
export const FETCH_TEMPLATE_LIST_FAILED = "FETCH_TEMPLATE_LIST_FAILED";
//
export const FETCH_TEMPLATE_ADMIN_LIST_STARTED =
  "FETCH_TEMPLATE_ADMIN_LIST_STARTED";
export const FETCH_TEMPLATE_ADMIN_LIST_SUCCESS =
  "FETCH_TEMPLATE_ADMIN_LIST_SUCCESS";
export const FETCH_TEMPLATE_ADMIN_LIST_FAILED =
  "FETCH_TEMPLATE_ADMIN_LIST_FAILED";
//

export const FETCH_TAGS_LIST_STARTED = "FETCH_TAGS_LIST_STARTED";
export const FETCH_TAGS_LIST_SUCCESS = "FETCH_TAGS_LIST_SUCCESS";
export const FETCH_TAGS_LIST_FAILED = "FETCH_TAGS_LIST_FAILED";

export const FILTER_SEARCH_CHANGE = "FILTER_SEARCH_CHANGE";
export const SELECT_REPORT = "SELECT_REPORT";

//User Landing page
export const GET_USER_PROJECTS_STARTED = "GET_USER_PROJECTS_STARTED";
export const GET_USER_PROJECTS_SUCCESS = "GET_USER_PROJECTS_SUCCESS";
export const GET_USER_PROJECTS_FAIL = "GET_USER_PROJECTS_FAIL";

//admin survey listing
export const GET_SURVEY_LIST_STARTED = "GET_SURVEY_LIST_STARTED";
export const GET_SURVEY_LIST_SUCCESS = "GET_SURVEY_LIST_SUCCESS";
export const GET_SURVEY_LIST_FAIL = "GET_SURVEY_LIST_FAIL";

//get project details
export const GET_PROJECT_DETAILS_STARTED = "GET_PROJECT_DETAILS_STARTED";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";
export const GET_PROJECT_DETAILS_FAIL = "GET_PROJECT_DETAILS_FAIL";

export const DELETE_SURVEY_LIST_STARTED = "DELETE_SURVEY_LIST_STARTED";
export const DELETE_SURVEY_LIST_SUCCESS = "DELETE_SURVEY_LIST_SUCCESS";
export const DELETE_SURVEY_LIST_FAIL = "DELETE_SURVEY_LIST_FAIL";

// NLP dashboard/storyboard

//question section
export const FETCH_QUESTION_TOPICS_REPRESENTATION_START =
  "FETCH_QUESTION_TOPICS_REPRESENTATION_START";
export const FETCH_QUESTION_TOPICS_REPRESENTATION_SUCCESS =
  "FETCH_QUESTION_TOPICS_REPRESENTATION_SUCCESS";
export const FETCH_QUESTION_TOPICS_REPRESENTATION_FAIL =
  "FETCH_QUESTION_TOPICS_REPRESENTATION_FAIL";

export const FETCH_QUESTION_TOPICS_WORDCLOUD_START =
  "FETCH_QUESTION_TOPICS_WORDCLOUD_START";
export const FETCH_QUESTION_TOPICS_WORDCLOUD_SUCCESS =
  "FETCH_QUESTION_TOPICS_WORDCLOUD_SUCCESS";
export const FETCH_QUESTION_TOPICS_WORDCLOUD_FAIL =
  "FETCH_QUESTION_TOPICS_WORDCLOUD_FAIL";

export const FETCH_QUESTION_TOPICSDATA_START =
  "FETCH_QUESTION_TOPICSDATA_START";
export const FETCH_QUESTION_TOPICSDATA_SUCCESS =
  "FETCH_QUESTION_TOPICSDATA_SUCCESS";
export const FETCH_QUESTION_TOPICSDATA_FAIL = "FETCH_QUESTION_TOPICSDATA_FAIL";
export const UPDATE_SELECTED_COLUMNS = "UPDATE_SELECTED_COLUMNS";

export const FETCH_QUESTION_COMMENTS_START = "FETCH_QUESTION_COMMENTS_START";
export const FETCH_QUESTION_COMMENTS_SUCCESS =
  "FETCH_QUESTION_COMMENTS_SUCCESS";
export const FETCH_QUESTION_COMMENTS_FAIL = "FETCH_QUESTION_COMMENTS_FAIL";

export const SWITCH_QUESTION_COMMENT_START = "SWITCH_QUESTION_COMMENT_START";
export const SWITCH_QUESTION_COMMENT_SUCCESS =
  "SWITCH_QUESTION_COMMENT_SUCCESS";
export const SWITCH_QUESTION_COMMENT_FAIL = "SWITCH_QUESTION_COMMENT_FAIL";

//overview section
export const FETCH_OVERVIEW_DATA_START = "FETCH_OVERVIEW_DATA_START";
export const FETCH_OVERVIEW_DATA_SUCCESS = "FETCH_OVERVIEW_DATA_SUCCESS";
export const FETCH_OVERVIEW_DATA_FAIL = "FETCH_OVERVIEW_DATA_FAIL";

//overview result section
export const FETCH_OVERVIEW_RESULT_DATA_START = "FETCH_OVERVIEW_RESULT_DATA_START";
export const FETCH_OVERVIEW_RESULT_DATA_SUCCESS = "FETCH_OVERVIEW_RESULT_DATA_SUCCESS";
export const FETCH_OVERVIEW_RESULT_DATA_FAIL = "FETCH_OVERVIEW_RESULT_DATA_FAIL";

//top results section
export const FETCH_TOP_RESULTS_START = "FETCH_TOP_RESULTS_START";
export const FETCH_TOP_RESULTS_SUCCESS = "FETCH_TOP_RESULTS_SUCCESS";
export const FETCH_TOP_RESULTS_FAIL = "FETCH_TOP_RESULTS_FAIL";

//nlp comparison
export const FETCH_NLPHEATMAP_DATA_START = "FETCH_NLPHEATMAP_DATA_START";
export const FETCH_NLPHEATMAP_DATA_SUCCESS = "FETCH_NLPHEATMAP_DATA_SUCCESS";
export const FETCH_NLPHEATMAP_DATA_FAIL = "FETCH_NLPHEATMAP_DATA_FAIL";

//nlp comparison heatmap
export const FETCH_NLP_HEATMAP_DATA_START = "FETCH_NLP_HEATMAP_DATA_START";
export const FETCH_NLP_HEATMAP_DATA_SUCCESS = "FETCH_NLP_HEATMAP_DATA_SUCCESS";
export const FETCH_NLP_HEATMAP_DATA_FAIL = "FETCH_NLP_HEATMAP_DATA_FAIL";

//nlp comparison heatmapquestiondata
export const FETCH_NLP_HEATMAP_QUESTION_DATA_START = "FETCH_NLP_HEATMAP_QUESTION_DATA_START";
export const FETCH_NLP_HEATMAP_QUESTION_DATA_SUCCESS = "FETCH_NLP_HEATMAP_QUESTION_DATA_SUCCESS";
export const FETCH_NLP_HEATMAP_QUESTION_DATA_FAIL = "FETCH_NLP_HEATMAP_QUESTION_DATA_FAIL";

//nlp tree data
export const FETCH_NLP_TREE_DATA_STARTED = "FETCH_NLP_TREE_DATA_STARTED";
export const FETCH_NLP_TREE_DATA_SUCCEED = "FETCH_NLP_TREE_DATA_SUCCEED";
export const FETCH_NLP_TREE_DATA_FAILED = "FETCH_NLP_TREE_DATA_FAILED";

//nlp admin
export const FETCH_HRIS_ADMIN_LIST_STARTED = "FETCH_HRIS_ADMIN_LIST_STARTED";
export const FETCH_HRIS_ADMIN_LIST_SUCCESS = "FETCH_HRIS_ADMIN_LIST_SUCCESS";
export const FETCH_HRIS_ADMIN_LIST_FAILED = "FETCH_HRIS_ADMIN_LIST_FAILED";

export const FETCH_DIMENSIONSLIST_STARTED = "FETCH_DIMENSIONSLIST_STARTED";
export const FETCH_DIMENSIONSLIST_SUCCESS = "FETCH_DIMENSIONSLIST_SUCCESS";
export const FETCH_DIMENSIONSLIST_FAILED = "FETCH_DIMENSIONSLIST_FAILED";


//
export const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS";
export const CLEAR_SESSION_EXPIRED = "CLEAR_SESSION_EXPIRED";

export const ADMIN_FORM_CHANGE = "ADMIN_FORM_CHANGE";
export const CLEAR_ADMIN_FORM = "CLEAR_ADMIN_FORM";

export const INVITE_MANAGERS_FORM_CHANGE = "INVITE_MANAGERS_FORM_CHANGE";
export const CLEAR_MANAGERS_INVITE_FORM = "CLEAR_MANAGERS_INVITE_FORM";

export const PEOPLE_SEARCH_FORM_CHANGE = "PEOPLE_SEARCH_FORM_CHANGE";
export const CLEAR_PEOPLE_SEARCH_FORM = "CLEAR_PEOPLE_SEARCH_FORM";

export const HRIS_UPLOAD_FORM_CHANGE = "HRIS_UPLOAD_FORM_CHANGE";
export const CLEAR_HRIS_UPLOAD_FORM = "CLEAR_HRIS_UPLOAD_FORM";

export const MAIL_TEMPLATE_FORM_CHANGE = "MAIL_TEMPLATE_FORM_CHANGE";
export const CLEAR_MAIL_TEMPLATE_FORM = "CLEAR_MAIL_TEMPLATE_FORM";
export const INVITE_FORM_CHANGE = "INVITE_FORM_CHANGE";
export const CLEAR_INVITE_FORM = "CLEAR_INVITE_FORM";

export const CLEAR_ORGANIZATION_FORM = "CLEAR_ORGANIZATION_FORM";
export const USERS_HRIS_FILE_CHANGE = "USERS_HRIS_FILE_CHANGE";
export const HRIS_HRIS_FILE_CHANGE = "HRIS_HRIS_FILE_CHANGE";

export const CLEAR_CONTACT_FORM = "CLEAR_CONTACT_FORM";
export const LOGS_PAGINATION_CHANGE = "LOGS_PAGINATION_CHANGE";
export const EMAIL_TEMPLATE_PAGINATION_CHANGE =
  "EMAIL_TEMPLATE_PAGINATION_CHANGE";
export const ADD_ADMIN_PAGINATION_CHANGE = "ADD_ADMIN_PAGINATION_CHANGE";
export const MANAGERS_PAGINATION_CHANGE = "MANAGERS_PAGINATION_CHANGE";
export const USERS_HRIS_PAGINATION_CHANGE = "USERS_HRIS_PAGINATION_CHANGE";
//survey
export const SURVEY_SEARCH_CHANGE = "SURVEY_SEARCH_CHANGE";
export const CLEAR_SURVEY_SEARCH = "CLEAR_SURVEY_SEARCH";
export const ON_SEARCH = "ON_SEARCH";

export const IMAGE_UPLOAD_START = "IMAGE_UPLOAD_START";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAILED = "IMAGE_UPLOAD_FAILED";

export const STOP_RESPONSE_COLLECTION_FAIL = "STOP_RESPONSE_COLLECTION_FAIL";
export const STOP_RESPONSE_COLLECTION_SUCCESS = "STOP_RESPONSE_COLLECTION_SUCCESS";
export const STOP_RESPONSE_COLLECTION_START = "STOP_RESPONSE_COLLECTION_START";

export const EXPORT_FORM_CHANGE = "EXPORT_FORM_CHANGE";
export const EXPORT_FORM_CLEAR = "EXPORT_FORM_CLEAR";

export const CLEAR_TOPIC_FILE = "CLEAR_TOPIC_FILE";

export const CREATE_PROJECT_FORM_CHANGE = "CREATE_PROJECT_FORM_CHANGE";
export const ADD_GOALS = "ADD_GOALS";
export const SELECT_GOALS = "SELECT_GOALS";
export const EDIT_GOALS = "EDIT_GOALS";
export const MARK_AS_OUTCOME = "MARK_AS_OUTCOME";

export const SELECT_QUESTION = "SELECT_QUESTION";
export const EDIT_QUESTION = "EDIT_QUESTION";
export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_ENGAGEMENT_BY_DEFAULT = "ADD_ENGAGEMENT_BY_DEFAULT";
export const ADD_DEMOGRAPHICS_BY_DEFAULT = "ADD_DEMOGRAPHICS_BY_DEFAULT";

export const SET_WIZARD_CONFIG = "SET_WIZARD_CONFIG";
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";

export const SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS";
export const SET_SUVREY_INVITATION = "SET_SUVREY_INVITATION";
export const SET_DEFAULT_WIZARD_STATE = "SET_DEFAULT_WIZARD_STATE";

export const ADD_REMINDER_START = "ADD_REMINDER_START";
export const ADD_REMINDER_SUCCESS = "ADD_REMINDER_SUCCESS";
export const ADD_REMINDER_FAILED = "ADD_REMINDER_FAILED";

export const EDIT_REMINDER_START = "EDIT_REMINDER_START";
export const EDIT_REMINDER_SUCCESS = "EDIT_REMINDER_SUCCESS";
export const EDIT_REMINDER_FAILED = "EDIT_REMINDER_FAILED";

export const LIST_REMINDER_START = "LIST_REMINDER_START";
export const LIST_REMINDER_SUCCESS = "LIST_REMINDER_SUCCESS";
export const LIST_REMINDER_FAILED = "LIST_REMINDER_FAILED";

export const SEND_PREVIEW_MAIL_START = "SEND_PREVIEW_MAIL_START";
export const SEND_PREVIEW_MAIL_SUCCESS = "SEND_PREVIEW_MAIL_SUCCESS";
export const SEND_PREVIEW_MAIL_FAILED = "SEND_PREVIEW_MAIL_FAILED";


export const FETCH_OUTLIERS_DATA_FAIL = "FETCH_OUTLIERS_DATA_FAIL";
export const FETCH_OUTLIERS_DATA_START = "FETCH_OUTLIERS_DATA_START";
export const FETCH_OUTLIERS_DATA_SUCCESS = "FETCH_OUTLIERS_DATA_SUCCESS";

export const CSV_DOWNLOAD_FAILED = "CSV_DOWNLOAD_FAILED";
export const CSV_DOWNLOAD_START = "CSV_DOWNLOAD_START";
export const CSV_DOWNLOAD_SUCCESS = "CSV_DOWNLOAD_SUCCESS";

export const GET_WIZARD_CONFIG_START = "GET_WIZARD_CONFIG_START";
export const GET_WIZARD_CONFIG_SUCCESS = "GET_WIZARD_CONFIG_SUCCESS";
export const GET_WIZARD_CONFIG_FAIL = "GET_WIZARD_CONFIG_FAIL";

export const SET_SELECTED_WIZARD_MENU = "SET_SELECTED_WIZARD_MENU";

export const GET_STORYBOARD_TOPICS_START = "GET_STORYBOARD_TOPICS_START";
export const GET_STORYBOARD_TOPICS_SUCCESS = "GET_STORYBOARD_TOPICS_SUCCESS";
export const GET_STORYBOARD_TOPICS_FAILED = "GET_STORYBOARD_TOPICS_FAILED";

export const GET_TOPIC_LIST_START = "GET_TOPIC_LIST_START";
export const GET_TOPIC_LIST_SUCCESS = "GET_TOPIC_LIST_SUCCESS";
export const GET_TOPIC_LIST_FAILED = "GET_TOPIC_LIST_FAILED";

export const GET_TEXT_QUESTION_LIST_START = "GET_TEXT_QUESTION_LIST_START";
export const GET_TEXT_QUESTION_LIST_SUCCESS = "GET_TEXT_QUESTION_LIST_SUCCESS";
export const GET_TEXT_QUESTION_LIST_FAILED = "GET_TEXT_QUESTION_LIST_FAILED";

export const GET_THEMES_LIST_START = "GET_THEMES_LIST_START";
export const GET_THEMES_LIST_SUCCESS = "GET_THEMES_LIST_SUCCESS";
export const GET_THEMES_LIST_FAILED = "GET_THEMES_LIST_FAILED";

export const REPROCESS_TOPICS_START = "REPROCESS_TOPICS_START";
export const REPROCESS_TOPICS_SUCCESS = "REPROCESS_TOPICS_SUCCESS";
export const REPROCESS_TOPICS_FAILED = "REPROCESS_TOPICS_FAILED";

export const COMPARISONTAB_UPDATE_PARTICIPATION_COLUMNS_LIST =
  "COMPARISONTAB_UPDATE_PARTICIPATION_COLUMNS_LIST";
export const COMPARISONTAB_DESELECT_PARTICIPATION_COLUMNS_LIST =
  "COMPARISONTAB_DESELECT_PARTICIPATION_COLUMNS_LIST";

export const RENAME_PEOPLE_START = "RENAME_PEOPLE_START";
export const RENAME_PEOPLE_SUCCESS = "RENAME_PEOPLE_SUCCESS";
export const RENAME_PEOPLE_FAILED = "RENAME_PEOPLE_FAILED";

export const DELETE_PEOPLE_START = "DELETE_PEOPLE_START";
export const DELETE_PEOPLE_SUCCESS = "DELETE_PEOPLE_SUCCESS";
export const DELETE_PEOPLE_FAILED = "DELETE_PEOPLE_FAILED";

export const UPLOAD_QUESTION_TOPIC_START = "UPLOAD_QUESTION_TOPIC_START";
export const UPLOAD_QUESTION_TOPIC_SUCCESS = "UPLOAD_QUESTION_TOPIC_SUCCESS";
export const UPLOAD_QUESTION_TOPIC_FAILED = "UPLOAD_QUESTION_TOPIC_FAILED";

export const UPLOAD_TRANSLATED_FILE_START = "UPLOAD_TRANSLATED_FILE_START";
export const UPLOAD_TRANSLATED_FILE_SUCCESS = "UPLOAD_TRANSLATED_FILE_SUCCESS";
export const UPLOAD_TRANSLATED_FILE_FAILED = "UPLOAD_TRANSLATED_FILE_FAILED";

export const CHECK_TRANSLATION_STATUS_START = "CHECK_TRANSLATION_STATUS_START";
export const CHECK_TRANSLATION_STATUS_SUCCESS = "CHECK_TRANSLATION_STATUS_SUCCESS";
export const CHECK_TRANSLATION_STATUS_FAILED = "CHECK_TRANSLATION_STATUS_FAILED";

export const LIST_TEXT_QUESTIONS_START = "LIST_TEXT_QUESTIONS_START";
export const LIST_TEXT_QUESTIONS_SUCCESS = "LIST_TEXT_QUESTIONS_SUCCESS";
export const LIST_TEXT_QUESTIONS_FAILED = "LIST_TEXT_QUESTIONS_FAILED";

export const MARK_AS_CUSTOM_TOPIC = "MARK_AS_CUSTOM_TOPIC";
export const SET_FILE_NAME = "SET_FILE_NAME";

export const REPROCESS_SURVEY_START = "REPROCESS_SURVEY_START";
export const REPROCESS_SURVEY_SUCCESS = "REPROCESS_SURVEY_SUCCESS";
export const REPROCESS_SURVEY_FAILED = "REPROCESS_SURVEY_FAILED";

export const MODIFY_TOPIC_STORY = 'MODIFY_TOPIC_STORY;'

export const CHANGE_QUOTE = 'CHANGE_QUOTE'

export const PREVIOUS_QUOTE = 'PREVIOUS_QUOTE'

export const UN_PUBLISH_SURVEY_START = "UN_PUBLISH_SURVEY_START";
export const UN_PUBLISH_SURVEY_SUCCESS = "UN_PUBLISH_SURVEY_SUCCESS";
export const UN_PUBLISH_SURVEY_FAILED = "UN_PUBLISH_SURVEY_FAILED";

export const FETCH_SURVEY_LIST_STARTED = 'FETCH_SURVEY_LIST_STARTED';
export const FETCH_SURVEY_LIST_SUCCESS = 'FETCH_SURVEY_LIST_SUCCESS';
export const FETCH_SURVEY_LIST_FAILED = 'FETCH_SURVEY_LIST_FAILED';
export const FETCH_SURVEY_DETAILS_STARTED = 'FETCH_SURVEY_DETAILS_STARTED';
export const FETCH_SURVEY_DETAILS_SUCCESS = 'FETCH_SURVEY_DETAILS_SUCCESS';
export const FETCH_SURVEY_DETAILS_FAILED = 'FETCH_SURVEY_DETAILS_FAILED';

export const FETCH_TREE_DATA_STARTED = "FETCH_TREE_DATA_STARTED";
export const FETCH_TREE_DATA_SUCCEED = "FETCH_TREE_DATA_SUCCEED";
export const FETCH_TREE_DATA_FAILED = "FETCH_TREE_DATA_FAILED";

export const FETCH_DASHBOARD_FILTER_START = 'FETCH_DASHBOARD_FILTER_START';
export const FETCH_DASHBOARD_FILTER_SUCCESS = 'FETCH_DASHBOARD_FILTER_SUCCESS';
export const FETCH_DASHBOARD_FILTER_FAILED = 'FETCH_DASHBOARD_FILTER_FAILED';

export const INVITE_VIEWER_START = 'INVITE_VIEWER_START';
export const INVITE_VIEWER_SUCCESS = 'INVITE_VIEWER_SUCCESS';
export const INVITE_VIEWER_FAILED = 'INVITE_VIEWER_FAILED';

export const LIST_VIEWERS_START = 'LIST_VIEWERS_START';
export const LIST_VIEWERS_SUCCESS = 'LIST_VIEWERS_SUCCESS';
export const LIST_VIEWERS_FAILED = 'LIST_VIEWERS_FAILED';

export const INVITE_VIEWER_FORM = 'INVITE_VIEWER_FORM';
export const CLEAR_INVITE_VIEWER_FORM = 'CLEAR_INVITE_VIEWER_FORM'

export const FETCH_SURVEY_REPORT_START = 'FETCH_SURVEY_REPORT_START';
export const FETCH_SURVEY_REPORT_SUCCESS = 'FETCH_SURVEY_REPORT_SUCCESS';
export const FETCH_SURVEY_REPORT_FAILED = 'FETCH_SURVEY_REPORT_FAILED';

export const STORYBOARD_FETCH_DRIVERS_DATA_START = "STORYBOARD_FETCH_DRIVERS_DATA_START";
export const STORYBOARD_FETCH_DRIVERS_DATA_FAIL = "STORYBOARD_FETCH_DRIVERS_DATA_FAIL";
export const STORYBOARD_FETCH_DRIVERS_DATA_SUCCESS = "STORYBOARD_FETCH_DRIVERS_DATA_SUCCESS";

export const GET_OUTLIERS_DATA_FAIL = 'GET_OUTLIERS_DATA_FAIL';
export const GET_OUTLIERS_DATA_START = 'GET_OUTLIERS_DATA_START';
export const GET_OUTLIERS_DATA_SUCCESS = 'GET_OUTLIERS_DATA_SUCCESS';
export const SET_STORYBOARD_OUTLIER_DATA = 'SET_STORYBOARD_OUTLIER_DATA';

export const SET_STORYBOARD_TOP_AND_BOTTOM_TOPICS = 'SET_STORYBOARD_TOP_AND_BOTTOM_TOPICS';
export const CLEAR_STORYBOARD_TOP_AND_BOTTOM_TOPICS = 'CLEAR_STORYBOARD_TOP_AND_BOTTOM_TOPICS';

export const FETCH_STORYBOARD_PARTICIPATION_START = 'FETCH_STORYBOARD_PARTICIPATION_START';
export const FETCH_STORYBOARD_PARTICIPATION_SUCCESS = 'FETCH_STORYBOARD_PARTICIPATION_SUCCESS';
export const FETCH_STORYBOARD_PARTICIPATION_FAILED = 'FETCH_STORYBOARD_PARTICIPATION_FAILED';

export const FETCH_STORYBOARD_DRIVERS_START = 'FETCH_STORYBOARD_DRIVERS_START';
export const FETCH_STORYBOARD_DRIVERS_SUCCESS = 'FETCH_STORYBOARD_DRIVERS_SUCCESS';
export const FETCH_STORYBOARD_DRIVERS_FAILED = 'FETCH_STORYBOARD_DRIVERS_FAILED';

export const FETCH_STORYBOARD_QUESTIONS_START = 'FETCH_STORYBOARD_QUESTIONS_START';
export const FETCH_STORYBOARD_QUESTIONS_SUCCESS = 'FETCH_STORYBOARD_QUESTIONS_SUCCESS';
export const FETCH_STORYBOARD_QUESTIONS_FAILED = 'FETCH_STORYBOARD_QUESTIONS_FAILED';

export const FETCH_STORYBOARD_GOING_WELL_START = 'FETCH_STORYBOARD_GOING_WELL_START';
export const FETCH_STORYBOARD_GOING_WELL_SUCCESS = 'FETCH_STORYBOARD_GOING_WELL_SUCCESS';
export const FETCH_STORYBOARD_GOING_WELL_FAILED = 'FETCH_STORYBOARD_GOING_WELL_FAILED';

export const FETCH_STORYBOARD_IMPROVEMENT_START = 'FETCH_STORYBOARD_IMPROVEMENT_START';
export const FETCH_STORYBOARD_IMPROVEMENT_SUCCESS = 'FETCH_STORYBOARD_IMPROVEMENT_SUCCESS';
export const FETCH_STORYBOARD_IMPROVEMENT_FAILED = 'FETCH_STORYBOARD_IMPROVEMENT_FAILED';

export const FETCH_STORYBOARD_LOW_OUTCOME_QUESTION_SCORE_SUCCESS = 'FETCH_STORYBOARD_LOW_OUTCOME_QUESTION_SCORE_SUCCESS';

export const FETCH_STORYBOARD_QUESTION_COMMENTS_START = 'FETCH_STORYBOARD_QUESTION_COMMENTS_START';
export const FETCH_STORYBOARD_QUESTION_COMMENTS_SUCCESS = 'FETCH_STORYBOARD_QUESTION_COMMENTS_SUCCESS';
export const FETCH_STORYBOARD_QUESTION_COMMENTS_FAILED = 'FETCH_STORYBOARD_QUESTION_COMMENTS_FAILED';

export const FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_FAIL = 'FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_FAIL';
export const FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_SUCCESS = 'FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_SUCCESS';
export const FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_START = 'FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_START'
export const SET_NEW_DASHBOARD_EXPLORE_DRIVER_DATA_FETCHED_FALSE = 'SET_NEW_DASHBOARD_EXPLORE_DRIVER_DATA_FETCHED_FALSE'
export const SET_NEW_DASHBOARD_EXPLORE_DRIVER_FILTERS = 'SET_NEW_DASHBOARD_EXPLORE_DRIVER_FILTERS'

export const FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FAIL = 'FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FAIL';
export const FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_SUCCESS = 'FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_SUCCESS';
export const FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_START = 'FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_START'
export const SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FETCHED_FALSE = 'SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FETCHED_FALSE'
export const SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_FILTERS = 'SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_FILTERS'

export const FETCH_EXPLORE_TOPIC_LIST_START = 'FETCH_EXPLORE_TOPIC_LIST_START';
export const FETCH_EXPLORE_TOPIC_LIST_SUCCESS = 'FETCH_EXPLORE_TOPIC_LIST_SUCCESS';
export const FETCH_EXPLORE_TOPIC_LIST_FAILED = 'FETCH_EXPLORE_TOPIC_LIST_FAILED';
export const RESET_EXPLORE_TOPIC_STATE = 'RESET_EXPLORE_TOPIC_STATE';
export const SET_QUOTE_MODIFICATION_LOADING_STATE = 'SET_QUOTE_MODIFICATION_LOADING_STATE';

export const FETCH_EXPLORE_QUESTIONS_START = 'FETCH_EXPLORE_QUESTIONS_START';
export const FETCH_EXPLORE_QUESTIONS_SUCCESS = 'FETCH_EXPLORE_QUESTIONS_SUCCESS';
export const FETCH_EXPLORE_QUESTIONS_FAILED = 'FETCH_EXPLORE_QUESTIONS_FAILED';
export const FETCH_COMPARAE_QUESTION_ID = "FETCH_COMPARAE_QUESTION_ID";
export const CLEAR_COMPARAE_QUESTION_ID = "CLEAR_COMPARAE_QUESTION_ID";

export const FETCH_EXPLORE_PARTICIPATION_START = 'FETCH_EXPLORE_PARTICIPATION_START';
export const FETCH_EXPLORE_PARTICIPATION_SUCCESS = 'FETCH_EXPLORE_PARTICIPATION_SUCCESS';
export const FETCH_EXPLORE_PARTICIPATION_FAILED = 'FETCH_EXPLORE_PARTICIPATION_FAILED';

export const FETCH_DEMOGRAPHICS_DATA_START = 'FETCH_DEMOGRAPHICS_DATA_START';
export const FETCH_DEMOGRAPHICS_DATA_SUCCESS =
  'FETCH_DEMOGRAPHICS_DATA_SUCCESS';
export const FETCH_DEMOGRAPHICS_DATA_FAIL = 'FETCH_DEMOGRAPHICS_DATA_FAIL';
export const CLEAR_EXPLORE_DEMOGRAPHICS_REDUCER =
  'CLEAR_EXPLORE_DEMOGRAPHICS_REDUCER';

export const FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_START = 'FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_START '
export const FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_SUCCESS = 'FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_SUCCESS '
export const FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_FAILED = 'FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_FAILED '

export const FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_START = 'FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_START '
export const FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_SUCCESS = 'FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_SUCCESS '
export const FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_FAILED = 'FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_FAILED '

export const EXPLORE_UPDATE_PARTICIPATION_COLUMNS_LIST =
  "EXPLORE_UPDATE_PARTICIPATION_COLUMNS_LIST";
export const EXPLORE_DESELECT_PARTICIPATION_COLUMNS = "EXPLORE_DESELECT_PARTICIPATION_COLUMNS"
export const FETCH_DEMOGRAPHICS_DIMENSIONS_STARTED =
  'FETCH_DEMOGRAPHICS_DIMENSIONS_STARTED';
export const FETCH_DEMOGRAPHICS_DIMENSIONS_SUCCESS =
  'FETCH_DEMOGRAPHICS_DIMENSIONS_SUCCESS';
export const FETCH_DEMOGRAPHICS_DIMENSIONS_FAILED =
  'FETCH_DEMOGRAPHICS_DIMENSIONS_FAILED';
export const UPDATE_DEMOGRAPHIC_REDUCER = 'UPDATE_DEMOGRAPHIC_REDUCER';

export const FETCH_EXPLORE_COMMENTS_DATA_START = 'FETCH_EXPLORE_COMMENTS_DATA_START '
export const FETCH_EXPLORE_COMMENTS_DATA_SUCCESS = 'FETCH_EXPLORE_COMMENTS_DATA_SUCCESS '
export const FETCH_EXPLORE_COMMENTS_DATA_FAILED = 'FETCH_EXPLORE_COMMENTS_DATA_FAILED '

export const FETCH_EXPLORE_RELATION_DATA_START = 'FETCH_EXPLORE_RELATION_DATA_START '
export const FETCH_EXPLORE_RELATION_DATA_SUCCESS = 'FETCH_EXPLORE_RELATION_DATA_SUCCESS '
export const FETCH_EXPLORE_RELATION_DATA_FAILED = 'FETCH_EXPLORE_RELATION_DATA_FAILED '

export const SORT_EXPLORE_TOPIC_LIST = "SORT_EXPLORE_TOPIC_LIST"

export const FETCH_EXPLORE_TOPIC_COMMENTS_DATA_START = 'FETCH_EXPLORE_TOPIC_COMMENTS_DATA_START '
export const FETCH_EXPLORE_TOPIC_COMMENTS_DATA_SUCCESS = 'FETCH_EXPLORE_TOPIC_COMMENTS_DATA_SUCCESS '
export const FETCH_EXPLORE_TOPIC_COMMENTS_DATA_FAILED = 'FETCH_EXPLORE_TOPIC_COMMENTS_DATA_FAILED '

export const FETCH_ALLTHEME_DATA_START = 'FETCH_ALLTHEME_DATA_START';
export const FETCH_ALLTHEME_DATA_SUCCESS = 'FETCH_ALLTHEME_DATA_SUCCESS';
export const FETCH_ALLTHEME_DATA_FAIL = 'FETCH_ALLTHEME_DATA_FAIL';

export const EXPLORE_FETCH_OUTLIERS_DATA_START = 'EXPLORE_FETCH_OUTLIERS_DATA_START';
export const EXPLORE_FETCH_OUTLIERS_DATA_SUCCESS = 'EXPLORE_FETCH_OUTLIERS_DATA_SUCCESS';
export const EXPLORE_FETCH_OUTLIERS_DATA_FAILED = 'EXPLORE_FETCH_OUTLIERS_DATA_FAILED';

export const CLEAR_EXPLORE_PARTICIPATION_REDUCER = 'CLEAR_EXPLORE_PARTICIPATION_REDUCER'
export const FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_START =
  'FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_START';
export const FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_SUCCESS =
  'FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_SUCCESS';
export const FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_FAIL =
  'FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_FAIL';
export const UPDATE_THEME_STATE_VALUES = 'UPDATE_THEME_STATE_VALUES';

export const EXPLORE_GET_DISTIRBUTION_DATA_START = "EXPLORE_GET_DISTIRBUTION_DATA_START";
export const EXPLORE_GET_DISTIRBUTION_DATA_SUCCESS = "EXPLORE_GET_DISTIRBUTION_DATA_SUCCESS";
export const EXPLORE_GET_DISTIRBUTION_DATA_FAILED = "EXPLORE_GET_DISTIRBUTION_DATA_FAILED";

export const FETCH_COMPLETE_SURVEY_LIST_STARTED = 'FETCH_COMPLETE_SURVEY_LIST_STARTED';
export const FETCH_COMPLETE_SURVEY_LIST_SUCCESS = 'FETCH_COMPLETE_SURVEY_LIST_SUCCESS';
export const FETCH_COMPLETE_SURVEY_LIST_FAILED = 'FETCH_COMPLETE_SURVEY_LIST_FAILED';

export const MANAGE_USERS_FETCH_USERS_STARTED = 'MANAGE_USERS_FETCH_USERS_STARTED'
export const MANAGE_USERS_FETCH_USERS_SUCCESS = 'MANAGE_USERS_FETCH_USERS_SUCCESS'
export const MANAGE_USERS_FETCH_USERS_FAILED = 'MANAGE_USERS_FETCH_USERS_FAILED'

export const MANAGE_USERS_INVITE_MANAGERS_STARTED = 'MANAGE_USERS_INVITE_MANAGERS_STARTED'
export const MANAGE_USERS_INVITE_MANAGERS_SUCCESS = 'MANAGE_USERS_INVITE_MANAGERS_SUCCESS'
export const MANAGE_USERS_INVITE_MANAGERS_FAILED = 'MANAGE_USERS_INVITE_MANAGERS_FAILED'


export const MANAGE_USERS_FETCH_HRIS_FILE_NAME_STARTED = 'MANAGE_USERS_FETCH_HRIS_FILE_NAME_STARTED'
export const MANAGE_USERS_FETCH_HRIS_FILE_NAME_SUCCESS = 'MANAGE_USERS_FETCH_HRIS_FILE_NAME_SUCCESS'
export const MANAGE_USERS_FETCH_HRIS_FILE_NAME_FAILED = 'MANAGE_USERS_FETCH_HRIS_FILE_NAME_FAILED'

export const MANAGE_EXPORT_GENERATE_REPORT_MAIL_STARTED = 'MANAGE_EXPORT_GENERATE_REPORT_MAIL_STARTED'
export const MANAGE_EXPORT_GENERATE_REPORT_MAIL_SUCCESS = 'MANAGE_EXPORT_GENERATE_REPORT_MAIL_SUCCESS'
export const MANAGE_EXPORT_GENERATE_REPORT_MAIL_FAILED = 'MANAGE_EXPORT_GENERATE_REPORT_MAIL_FAILED'

export const COMMENTS_FETCH_SENTIMENTS_STARTED = 'COMMENTS_FETCH_SENTIMENTS_STARTED';
export const COMMENTS_FETCH_SENTIMENTS_SUCCESS = 'COMMENTS_FETCH_SENTIMENTS_SUCCESS';
export const COMMENTS_FETCH_SENTIMENTS_FAILED = 'COMMENTS_FETCH_SENTIMENTS_FAILED';

export const GET_BATCH_COMMENT_QUESTIONS_STARTED = 'GET_BATCH_COMMENT_QUESTIONS_STARTED';
export const GET_BATCH_COMMENT_QUESTIONS_SUCCESS = 'GET_BATCH_COMMENT_QUESTIONS_SUCCESS';
export const GET_BATCH_COMMENT_QUESTIONS_FAILED = 'GET_BATCH_COMMENT_QUESTIONS_FAILED';

export const UPDATE_COMMENT_QUESTION_ID = 'UPDATE_COMMENT_QUESTION_ID';

export const DOWNLOADS_COMMENTS_BY_BATCH_ID_START = 'DOWNLOADS_COMMENTS_BY_BATCH_ID_START';
export const DOWNLOADS_COMMENTS_BY_BATCH_ID_SUCCESS = 'DOWNLOADS_COMMENTS_BY_BATCH_ID_SUCCESS';
export const DOWNLOADS_COMMENTS_BY_BATCH_ID_FAILED = 'DOWNLOADS_COMMENTS_BY_BATCH_ID_FAILED';

export const MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_STARTED = 'MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_STARTED'
export const MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_SUCCESS = 'MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_SUCCESS'
export const MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_FAILED = 'MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_FAILED'

export const MANAGE_VIEWERS_FETCH_LIST_VIEWERS_STARTED = 'MANAGE_VIEWERS_FETCH_LIST_VIEWERS_STARTED'
export const MANAGE_VIEWERS_FETCH_LIST_VIEWERS_SUCCESS = 'MANAGE_VIEWERS_FETCH_LIST_VIEWERS_SUCCESS'
export const MANAGE_VIEWERS_FETCH_LIST_VIEWERS_FAILED = 'MANAGE_VIEWERS_FETCH_LIST_VIEWERS_FAILED'

export const MANAGE_RESPONSES_FETCH_RESPONSES_STARTED = 'MANAGE_MANAGE_RESPONSES_FETCH_RESPONSES_STARTED'
export const MANAGE_RESPONSES_FETCH_RESPONSES_SUCCESS = 'MANAGE_MANAGE_RESPONSES_FETCH_RESPONSES_SUCCESS'
export const MANAGE_RESPONSES_FETCH_RESPONSES_FAILED = 'MANAGE_MANAGE_RESPONSES_FETCH_RESPONSES_FAILED'
export const GET_COMMENT_THEMES_STARTED = 'GET_COMMENT_THEMES_STARTED'
export const GET_COMMENT_THEMES_SUCCESS = 'GET_COMMENT_THEMES_SUCCESS'
export const GET_COMMENT_THEMES_FAILED = 'GET_COMMENT_THEMES_FAILED'

export const UPDATE_GET_COMMENTS_SELECTED_TOPIC = "UPDATE_GET_COMMENTS_SELECTED_TOPIC"

export const UPDATE_COMMENTS_SEARCH_KEY = "UPDATE_COMMENTS_SEARCH_KEY"

export const FETCH_NEWDASHBOARD_BATCH_QUESTIONS_START = "FETCH_NEWDASHBOARD_BATCH_QUESTIONS_START"
export const FETCH_NEWDASHBOARD_BATCH_QUESTIONS_SUCCESS = "FETCH_NEWDASHBOARD_BATCH_QUESTIONS_SUCCESS"
export const FETCH_NEWDASHBOARD_BATCH_QUESTIONS_FAILED = "FETCH_NEWDASHBOARD_BATCH_QUESTIONS_FAILED"

export const FETCH_HEATMAP_DATA_START = "FETCH_HEATMAP_DATA_START"
export const FETCH_HEATMAP_DATA_SUCCESS = "FETCH_HEATMAP_DATA_SUCCESS"
export const FETCH_HEATMAP_DATA_FAILED = "FETCH_HEATMAP_DATA_FAILED"

export const FETCH_HEATMAP_QUESTIONS_START = "FETCH_HEATMAP_QUESTIONS_START"
export const FETCH_HEATMAP_QUESTIONS_SUCCESS = "FETCH_HEATMAP_QUESTIONS_SUCCESS"
export const FETCH_HEATMAP_QUESTIONS_FAILED = "FETCH_HEATMAP_QUESTIONS_FAILED"

export const FETCH_HEATMAP_HEADERS_START = "FETCH_HEATMAP_HEADERS_START"
export const FETCH_HEATMAP_HEADERS_SUCCESS = "FETCH_HEATMAP_HEADERS_SUCCESS"
export const FETCH_HEATMAP_HEADERS_FAILED = "FETCH_HEATMAP_HEADERS_FAILED"

export const FETCH_OVERALL_EXPLORE_PARTICIPATION_START = "FETCH_OVERALL_EXPLORE_PARTICIPATION_START"
export const FETCH_OVERALL_EXPLORE_PARTICIPATION_SUCCESS = "FETCH_OVERALL_EXPLORE_PARTICIPATION_SUCCESS"
export const FETCH_OVERALL_EXPLORE_PARTICIPATION_FAILED = "FETCH_OVERALL_EXPLORE_PARTICIPATION_FAILED"

export const NEW_DASHBOARD_SEND_FEEDBACK_START = 'NEW_DASHBOARD_SEND_FEEDBACK_START';

export const SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_STARTED = 'SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_STARTED';
export const SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_SUCCESS = 'SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_SUCCESS';
export const SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_FAILED = 'SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_FAILED';

export const FETCH_SURVEY_PARTICIPATION_FAILED = 'FETCH_SURVEY_PARTICIPATION_FAILED';
export const FETCH_SURVEY_PARTICIPATION_SUCCESS = 'FETCH_SURVEY_PARTICIPATION_SUCCESS';
export const FETCH_SURVEY_PARTICIPATION_START = 'FETCH_SURVEY_PARTICIPATION_START';
export const CLEAR_SURVEY_PARTICIPATION_REDUCER = 'CLEAR_SURVEY_PARTICIPATION_REDUCER';

export const SURVEY_UPDATE_PARTICIPATION_COLUMNS_LIST = "SURVEY_UPDATE_PARTICIPATION_COLUMNS_LIST";
export const SURVEY_DESELECT_PARTICIPATION_COLUMNS = "SURVEY_DESELECT_PARTICIPATION_COLUMNS"

export const SURVEY_GET_DISTIRBUTION_DATA_START = "SURVEY_GET_DISTIRBUTION_DATA_START";
export const SURVEY_GET_DISTIRBUTION_DATA_SUCCESS = "SURVEY_GET_DISTIRBUTION_DATA_SUCCESS";
export const SURVEY_GET_DISTIRBUTION_DATA_FAILED = "SURVEY_GET_DISTIRBUTION_DATA_FAILED";

export const SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE = 'SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE'
export const SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE_SUCCESS = 'SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE_SUCCESS'

export const FETCH_DASHBOARD_COMMENTS_FILTER_START = 'FETCH_DASHBOARD_COMMENTS_FILTER_START'
export const FETCH_DASHBOARD_COMMENTS_FILTER_SUCCESS = 'FETCH_DASHBOARD_COMMENTS_FILTER_SUCCESS'
export const FETCH_DASHBOARD_COMMENTS_FILTER_FAILED = 'FETCH_DASHBOARD_COMMENTS_FILTER_FAILED'

export const CHANGE_DASHBOARD_SEARCH_FILTER = 'CHANGE_DASHBOARD_SEARCH_FILTER'

export const FETCH_STORYBOARD_DEMOGRAPHICS_START = 'FETCH_STORYBOARD_DEMOGRAPHICS_START'
export const FETCH_STORYBOARD_DEMOGRAPHICS_SUCCESS = 'FETCH_STORYBOARD_DEMOGRAPHICS_SUCCESS'
export const FETCH_STORYBOARD_DEMOGRAPHICS_FAILED = 'FETCH_STORYBOARD_DEMOGRAPHICS_FAILED'

export const FETCH_DASHBOARD_EXPLORE_FILTER_START = 'FETCH_DASHBOARD_EXPLORE_FILTER_START'
export const FETCH_DASHBOARD_EXPLORE_FILTER_SUCCESS = 'FETCH_DASHBOARD_EXPLORE_FILTER_SUCCESS'
export const FETCH_DASHBOARD_EXPLORE_FILTER_FAILED = 'FETCH_DASHBOARD_EXPLORE_FILTER_FAILED'
export const CHANGE_DASHBOARD_EXPLORE_SEARCH_FILTER = 'CHANGE_DASHBOARD_EXPLORE_SEARCH_FILTER'

export const SET_SELECTED_QUESTION_TOPIC = 'SET_SELECTED_QUESTION_TOPIC'

export const FETCH_SURVEYS_STARTED = "FETCH_SURVEYS_STARTED"
export const FETCH_SURVEYS_SUCCESS = "FETCH_SURVEYS_SUCCESS"
export const FETCH_SURVEYS_FAILED = "FETCH_SURVEYS_FAILED"

export const SET_SEARCH_TEXT_FOR_SURVEY_PAGE = 'SET_SEARCH_TEXT_FOR_SURVEY_PAGE'

export const SET_PAGE_NUMBER_FOR_SURVEY_PAGE = 'SET_PAGE_NUMBER_FOR_SURVEY_PAGE'

export const SET_SORT_ORDER_AND_SORT_FIELD = 'SET_SORT_ORDER_AND_SORT_FIELD'

export const FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_STARTED = 'FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_STARTED'
export const FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_SUCCESS = 'FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_SUCCESS'
export const FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_FAILED = 'FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_FAILED'

export const DISABLE_EXPLORE_TOPIC_LOADING = "DISABLE_EXPLORE_TOPIC_LOADING"

export const NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_START = 'NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_START'
export const NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_SUCCESS = 'NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_SUCCESS'
export const NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_FAILED = 'NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_FAILED'

export const MODIFY_PROJECT_STATUS_START = 'MODIFY_PROJECT_STATUS_START'
export const MODIFY_PROJECT_STATUS_SUCCESS = 'MODIFY_PROJECT_STATUS_SUCCESS'
export const MODIFY_PROJECT_STATUS_FAILED = 'MODIFY_PROJECT_STATUS_FAILED'

export const PUBLISH_SURVEY_START = 'PUBLISH_SURVEY_START'
export const PUBLISH_SURVEY_SUCCESS = 'PUBLISH_SURVEY_SUCCESS'
export const PUBLISH_SURVEY_FAILED = 'PUBLISH_SURVEY_FAILED'

export const FETCH_PROJECT_DETAILS_START = 'FETCH_PROJECT_DETAILS_START'
export const FETCH_PROJECT_DETAILS_SUCCESS = 'FETCH_PROJECT_DETAILS_SUCCESS'
export const FETCH_PROJECT_DETAILS_FAIL = 'FETCH_PROJECT_DETAILS_FAILED'

export const FETCH_SURVEYWIZARD_PARTICIPATION_START = 'FETCH_SURVEYWIZARD_PARTICIPATION_START'
export const FETCH_SURVEYWIZARD_PARTICIPATION_SUCCESS = 'FETCH_SURVEYWIZARD_PARTICIPATION_SUCCESS'
export const FETCH_SURVEYWIZARD_PARTICIPATION_FAIL = 'FETCH_SURVEYWIZARD_PARTICIPATION_FAIL'

export const SURVEYWIZARD_UPDATE_PARTICIPATION_COLUMN_LIST = 'SURVEYWIZARD_UPDATE_PARTICIPATION_COLUMN_LIST'

export const SURVEYWIZARD_DESELECT_PARTICIPATION_COLUMN = 'SURVEYWIZARD_DESELECT_PARTICIPATION_COLUMN'

export const SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_STARTED = 'SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_STARTED'
export const SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_SUCCESS = 'SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_SUCCESS'
export const SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_FAILED = 'SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_FAILED'

export const SURVEY_WIZARD_FETCH_SURVEY_LIST_START = 'SURVEY_WIZARD_FETCH_SURVEY_LIST_START'
export const SURVEY_WIZARD_FETCH_SURVEY_LIST_SUCCESS = 'SURVEY_WIZARD_FETCH_SURVEY_LIST_SUCCESS'
export const SURVEY_WIZARD_FETCH_SURVEY_LIST_FAIL = 'SURVEY_WIZARD_FETCH_SURVEY_LIST_FAIL'

export const FETCH_COHORT_CHILD_REMAP = 'FETCH_COHORT_CHILD_REMAP'

export const FETCH_SURVEY_WIZARD_PROJECT_DETAILS_START = 'FETCH_SURVEY_WIZARD_PROJECT_DETAILS_START'
export const FETCH_SURVEY_WIZARD_PROJECT_DETAILS_SUCCCESS = 'FETCH_SURVEY_WIZARD_PROJECT_DETAILS_SUCCESS'
export const FETCH_SURVEY_WIZARD_PROJECT_DETAILS_FAIL = 'FETCH_SURVEY_WIZARD_PROJECT_DETAILS_FAIL'

export const NEW_PROJECT_PAGE_CREATE_PROJECT_START = 'NEW_PROJECT_PAGE_CREATE_PROJECT_START'
export const NEW_PROJECT_PAGE_CREATE_PROJECT_SUCCESS = 'NEW_PROJECT_PAGE_CREATE_PROJECT_SUCCESS'
export const NEW_PROJECT_PAGE_CREATE_PROJECT_FAIL = 'NEW_PROJECT_PAGE_CREATE_PROJECT_FAIL'

export const SURVEY_WIZARD_FETCH_HRIS_FILE_START = 'SURVEY_WIZARD_FETCH_HRIS_FILE_START'
export const SURVEY_WIZARD_FETCH_HRIS_FILE_SUCCESS = 'SURVEY_WIZARD_FETCH_HRIS_FILE_SUCCESS'
export const SURVEY_WIZARD_FETCH_HRIS_FILE_FAIL = 'SURVEY_WIZARD_FETCH_HRIS_FILE_FAIL'

export const SURVEY_WIZARD_MODIFY_PROJECT_DATA_START = 'SURVEY_WIZARD_MODIFY_PROJECT_DATA_START'
export const SURVEY_WIZARD_MODIFY_PROJECT_DATA_SUCCESS = 'SURVEY_WIZARD_MODIFY_PROJECT_DATA_SUCCESS'
export const SURVEY_WIZARD_MODIFY_PROJECT_DATA_FAIL = 'SURVEY_WIZARD_MODIFY_PROJECT_DATA_FAIL'

export const SET_SETUP_LOCAL_STATE_DATA = 'SET_SETUP_LOCAL_STATE_DATA'

export const EDIT_SURVEY_MARK_AS_OUTCOME = 'EDIT_SURVEY_MARK_AS_OUTCOME'
export const EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_TOPIC = 'EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_TOPIC'
export const EDIT_SURVEY_DELETE_TOPIC = 'EDIT_SURVEY_DELETE_TOPIC'
export const EDIT_SURVEY_TOPICS_DRAG_AND_DROP = 'EDIT_SURVEY_TOPICS_DRAG_AND_DROP'
export const EDIT_SURVEY_TOPIC_OPTION_CHANGE = 'EDIT_SURVEY_TOPIC_OPTION_CHANGE'
export const EDIT_SURVEY_ADD_TOPIC = 'EDIT_SURVEY_ADD_TOPIC'
export const EDIT_SURVEY_ADD_STATEMENT = 'EDIT_SURVEY_ADD_STATEMENT'
export const EDIT_SURVEY_DELETE_STATEMENT = 'EDIT_SURVEY_DELETE_STATEMENT'
export const EDIT_SURVEY_ON_BLUR_INPUTBOX = 'EDIT_SURVEY_ON_BLUR_INPUTBOX'
export const EDIT_SURVEY_ON_BLUR_DESCRIPTION_INPUTBOX = 'EDIT_SURVEY_ON_BLUR_DESCRIPTION_INPUTBOX'
export const EDIT_SURVEY_ADD_CUSTOMTOPIC = 'EDIT_SURVEY_ADD_CUSTOMTOPIC'
export const EDIT_SURVEY_ADD_CUSTOM_TOPIC_STATEMENT_STATEMENT = 'EDIT_SURVEY_ADD_CUSTOM_TOPIC_STATEMENT_STATEMENT'
export const EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_OPEN_ENDED_QUESTIONS = 'EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_OPEN_ENDED_QUESTIONS'
export const EDIT_SURVEY_ON_BLUR_OPEN_ENDED_HEADING_INPUTBOX = 'EDIT_SURVEY_ON_BLUR_OPEN_ENDED_HEADING_INPUTBOX'
export const EDIT_SURVEY_ON_BLUR_OPEN_ENDED_QUESTION_LABEL_INPUTBOX = 'EDIT_SURVEY_ON_BLUR_OPEN_ENDED_QUESTION_LABEL_INPUTBOX'
export const EDIT_SURVEY_DELETE_OPEN_ENDED_QUESTION = 'EDIT_SURVEY_DELETE_OPEN_ENDED_QUESTION'
export const EDIT_SURVEY_ADD_OPEN_ENDED_QUESTIONS = 'EDIT_SURVEY_ADD_OPEN_ENDED_QUESTIONS'
export const EDIT_SURVEY_ONCHANGE_PROJECT_NAME = 'EDIT_SURVEY_ONCHANGE_PROJECT_NAME'
export const EDIT_SURVEY_ONCHANGE_ABOUT_SURVEY = 'EDIT_SURVEY_ONCHANGE_ABOUT_SURVEY'
export const EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_DEMOGRAPHICS_QUESTIONS = 'EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_DEMOGRAPHICS_QUESTIONS'
export const EDIT_SURVEY_DEMOGRAPHICS_DRAG_AND_DROP = 'EDIT_SURVEY_DEMOGRAPHICS_DRAG_AND_DROP'
export const EDIT_SURVEY_ADD_DEMOGRAPHIC_ANSWER_OPTION = 'EDIT_SURVEY_ADD_DEMOGRAPHIC_ANSWER_OPTION'
export const EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_HEADING = 'EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_HEADING'
export const EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_QUESTION_LABEL = 'EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_QUESTION_LABEL'
export const EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_ANSWER_OPTION = 'EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_ANSWER_OPTION'
export const EDIT_SURVEY_DELETE_DEMOGRAPHIC_QUESTION = 'EDIT_SURVEY_DELETE_DEMOGRAPHIC_QUESTION'
export const EDIT_SURVEY_DELETE_DEMOGRAPHIC_QUESTION_ANSWER = 'EDIT_SURVEY_DELETE_DEMOGRAPHIC_QUESTION_ANSWER'
export const EDIT_SURVEY_UNMARK_OUTCOME_TOPIC = 'EDIT_SURVEY_UNMARK_OUTCOME_TOPIC'
export const EDIT_SURVEY_ADD_DEMOGRAPHIC_QUESTION = 'EDIT_SURVEY_ADD_DEMOGRAPHIC_QUESTION'

export const SETUP_SEND_PREVIEW_MAIL_START = 'SETUP_SEND_PREVIEW_MAIL_START'
export const SETUP_SEND_PREVIEW_MAIL_SUCCESS = 'SETUP_SEND_PREVIEW_MAIL_SUCCESS'
export const SETUP_SEND_PREVIEW_MAIL_FAIL = 'SETUP_SEND_PREVIEW_MAIL_FAIL'

export const NEW_WIZARD_UPLOAD_TRANSLATED_FILE_START = "NEW_WIZARD_UPLOAD_TRANSLATED_FILE_START";
export const NEW_WIZARD_UPLOAD_TRANSLATED_FILE_SUCCESS = "NEW_WIZARD_UPLOAD_TRANSLATED_FILE_SUCCESS";
export const NEW_WIZARD_UPLOAD_TRANSLATED_FILE_FAILED = "NEW_WIZARD_UPLOAD_TRANSLATED_FILE_FAILED";

export const NEW_WIZARD_CHECK_TRANSLATION_STATUS_START = "NEW_WIZARD_CHECK_TRANSLATION_STATUS_START";
export const NEW_WIZARD_CHECK_TRANSLATION_STATUS_SUCCESS = "NEW_WIZARD_CHECK_TRANSLATION_STATUS_SUCCESS";
export const NEW_WIZARD_CHECK_TRANSLATION_STATUS_FAILED = "NEW_WIZARD_CHECK_TRANSLATION_STATUS_FAILED";

export const FETCH_STORYBOARD_FILTER_STATUS_STARTED = 'FETCH_STORYBOARD_FILTER_STATUS_STARTED'
export const FETCH_STORYBOARD_FILTER_STATUS_SUCCESS = 'FETCH_STORYBOARD_FILTER_STATUS_SUCCESS'
export const FETCH_STORYBOARD_FILTER_STATUS_FAILED = 'FETCH_STORYBOARD_FILTER_STATUS_FAILED'
export const STORYBOARD_FILTER_STATUS_CHANGE = 'TORYBOARD_FILTER_STATUS_CHANGE'

export const FETCH_STORYBOARD_ELEMNTS_STARTED = 'FETCH_STORYBOARD_ELEMNTS_STARTED';
export const FETCH_STORYBOARD_ELEMNTS_SUCCESS = 'FETCH_STORYBOARD_ELEMNTS_SUCCESS';
export const FETCH_STORYBOARD_ELEMNTS_FAILED = 'FETCH_STORYBOARD_ELEMNTS_FAILED';

export const FETCH_STORYBOARD_STATUS_STARTED = 'FETCH_STORYBOARD_STATUS_STARTED'
export const FETCH_STORYBOARD_STATUS_SUCCESS = 'FETCH_STORYBOARD_STATUS_SUCCESS'
export const FETCH_STORYBOARD_STATUS_FAILED = 'FETCH_STORYBOARD_STATUS_FAILED'
export const STORYBOARD_STATUS_CHANGE = 'TORYBOARD_STATUS_CHANGE'

export const TOPICS_SET_FILTER_SUCCESS = 'TOPICS_SET_FILTER_SUCCESS'
export const STORYBOARD_FILTER_CHANGE = 'STORYBOARD_FILTER_CHANGE'

export const UPDATE_HEATMAP_SCORE_LEVEL = 'UPDATE_HEATMAP_SCORE_LEVEL'
export const UPDATE_HEATMAP_COMPARISON_LEVEL = 'UPDATE_HEATMAP_COMPARISON_LEVEL'

export const FETCH_BENCHMARK_DATA_START = 'FETCH_BENCHMARK_DATA_START'
export const FETCH_BENCHMARK_DATA_SUCCESS = 'FETCH_BENCHMARK_DATA_SUCCESS'
export const FETCH_BENCHMARK_DATA_FAIL = 'FETCH_BENCHMARK_DATA_FAIL'

export const EDIT_BENCHMARK_DATA = "EDIT_BENCHMARK_DATA"

export const UPDATE_BENCHMARK_DATA_START = 'UPDATE_BENCHMARK_DATA_START'
export const UPDATE_BENCHMARK_DATA_SUCCESS = 'UPDATE_BENCHMARK_DATA_SUCCESS'
export const UPDATE_BENCHMARK_DATA_FAIL = 'UPDATE_BENCHMARK_DATA_FAIL'
export const EXPLORE_TOPIC_ADMIN_BARCHART_DATA_START = 'EXPLORE_TOPIC_ADMIN_BARCHART_DATA_START'
export const EXPLORE_TOPIC_ADMIN_BARCHART_DATA_SUCCESS = 'EXPLORE_TOPIC_ADMIN_BARCHART_DATA_SUCCESS'
export const EXPLORE_TOPIC_ADMIN_BARCHART_DATA_FAILED = 'EXPLORE_TOPIC_ADMIN_BARCHART_DATA_FAILED'

export const ADD_DESCRIPTIVE_QUESTION = 'ADD_DESCRIPTIVE_QUESTION'
export const EDIT_DESCRIPTIVE_TEXT_HEADING = 'EDIT_DESCRIPTIVE_TEXT_HEADING'
export const ONCHANGE_QUESTION = 'ONCHANGE_QUESTION'

export const FETCH_EXPLORE_DIMENSIONS_STARTED = 'FETCH_EXPLORE_DIMENSIONS_STARTED';
export const FETCH_EXPLORE_DIMENSIONS_SUCCESS = 'FETCH_EXPLORE_DIMENSIONS_SUCCESS';
export const FETCH_EXPLORE_DIMENSIONS_FAILED = 'FETCH_EXPLORE_DIMENSIONS_FAILED';

export const SET_EXPLORE_TOPIC = 'SET_EXPLORE_TOPIC';

export const SET_THRESHOLD_VALUE_SUCCESS = 'SET_THRESHOLD_VALUE_SUCCESS';
export const SET_THRESHOLD_VALUE_FAILED = 'SET_THRESHOLD_VALUE_FAILED';
export const SET_THRESHOLD_VALUE_STARTED = ' SET_THRESHOLD_VALUE_STARTED';

export const GET_THRESHOLD_VALUE_SUCCESS = 'GET_THRESHOLD_VALUE_SUCCESS';
export const GET_THRESHOLD_VALUE_FAILED = 'GET_THRESHOLD_VALUE_FAILED';
export const GET_THRESHOLD_VALUE_STARTED = ' GET_THRESHOLD_VALUE_STARTED';

export const SET_API_CALLED_KEY_EXPLORE_TOPICS = 'SET_API_CALLED_KEY'

export const SET_API_CALLED_KEY_EXPLORE_QUESTIONS = 'SET_API_CALLED_KEY_EXPLORE_QUESTIONS'

//Benchmark2

export const EDIT_TREND_BENCHMARK_DATA = 'EDIT_TREND_BENCHMARK_DATA'

export const FETCH_TREND_BENCHMARK_DATA_START = 'FETCH_TREND_BENCHMARK_DATA_START'
export const FETCH_TREND_BENCHMARK_DATA_SUCCESS = 'FETCH_TREND_BENCHMARK_DATA_SUCCESS'
export const FETCH_TREND_BENCHMARK_DATA_FAIL = 'FETCH_TREND_BENCHMARK_DATA_FAIL'

export const UPDATE_TREND_BENCHMARK_DATA_START = 'UPDATE_TREND_BENCHMARK_DATA_START'
export const UPDATE_TREND_BENCHMARK_DATA_SUCCESS = 'UPDATE_TREND_BENCHMARK_DATA_SUCCESS'
export const UPDATE_TREND_BENCHMARK_DATA_FAIL = 'UPDATE_TREND_BENCHMARK_DATA_FAIL'
export const FETCH_STORYBOARD_FILTER_START = 'FETCH_STORYBOARD_FILTER_START'
export const FETCH_STORYBOARD_FILTER_SUCCESS = 'FETCH_STORYBOARD_FILTER_SUCCESS'
export const FETCH_STORYBOARD_FILTER_FAIL = 'FETCH_STORYBOARD_FILTER_FAIL'

//Revoke Manager

export const REVOKE_USERS_START = 'REVOKE_USERS_START'
export const REVOKE_USERS_SUCCESS = 'REVOKE_USERS_SUCCESS'
export const REVOKE_USERS_FAIL = 'REVOKE_USERS_FAIL'

//

export const RESET_FETCH_DATA_KEY_HEATMAP = 'RESET_FETCH_DATA_KEY_HEATMAP'

//Logo upload

export const ON_CHANGE_LOGO = 'ON_CHANGE_LOGO'

//managerHeatmap

export const FETCH_MANAGER_HEATMAP_DATA_START = "FETCH_MANAGER_HEATMAP_DATA_START"
export const FETCH_MANAGER_HEATMAP_DATA_SUCCESS = "FETCH_MANAGER_HEATMAP_DATA_SUCCESS"
export const FETCH_MANAGER_HEATMAP_DATA_FAILED = "FETCH_MANAGER_HEATMAP_DATA_FAILED"

export const RESET_MANAGER_FETCH_DATA_KEY_HEATMAP = 'RESET_MANAGER_FETCH_DATA_KEY_HEATMAP'

export const FETCH_MANAGER_HEATMAP_QUESTIONS_START = "FETCH_MANAGER_HEATMAP_QUESTIONS_START"
export const FETCH_MANAGER_HEATMAP_QUESTIONS_SUCCESS = "FETCH_MANAGER_HEATMAP_QUESTIONS_SUCCESS"
export const FETCH_MANAGER_HEATMAP_QUESTIONS_FAILED = "FETCH_MANAGER_HEATMAP_QUESTIONS_FAILED"

export const FETCH_MANAGER_HEATMAP_HEADERS_START = "FETCH_MANAGER_HEATMAP_HEADERS_START"
export const FETCH_MANAGER_HEATMAP_HEADERS_SUCCESS = "FETCH_MANAGER_HEATMAP_HEADERS_SUCCESS"
export const FETCH_MANAGER_HEATMAP_HEADERS_FAILED = "FETCH_MANAGER_HEATMAP_HEADERS_FAILED"

export const UPDATE_MANAGER_HEATMAP_SCORE_LEVEL = 'UPDATE_MANAGER_HEATMAP_SCORE_LEVEL'
export const UPDATE_MANAGER_HEATMAP_COMPARISON_LEVEL = 'UPDATE_MANAGER_HEATMAP_COMPARISON_LEVEL'
//New forget password

export const NEW_FORGOT_PASSWORD_PROCESS_STARTED = "NEW_FORGOT_PASSWORD_PROCESS_STARTED";
export const NEW_FORGOT_PASSWORD_PROCESS_SUCCESS = "NEW_FORGOT_PASSWORD_PROCESS_SUCCESS";
export const NEW_FORGOT_PASSWORD_PROCESS_FAILED = "NEW_FORGOT_PASSWORD_PROCESS_FAILED";
export const NEW_FORGOT_PASSWORD_CLEAR_STATE = "NEW_FORGOT_PASSWORD_CLEAR_STATE";

//New reset password

export const NEW_RESET_PASSWORD_PROCESS_FAILED = 'NEW_RESET_PASSWORD_PROCESS_FAILED'
export const NEW_RESET_PASSWORD_PROCESS_SUCCESS = 'NEW_RESET_PASSWORD_PROCESS_SUCCESS'
export const NEW_RESET_PASSWORD_PROCESS_STARTED = 'NEW_RESET_PASSWORD_PROCESS_STARTED'
export const NEW_RESET_PASSWORD_CLEAR_STATE = 'NEW_RESET_PASSWORD_CLEAR_STATE'

//Select-Multiple-option

export const EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_MULTIPLE_SELECTION_QUESTIONS = 'EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_MULTIPLE_SELECTION_QUESTIONS'
export const EDIT_SURVEY_MULTIPLE_SELECTION_QUESTIONS_DRAG_AND_DROP = 'EDIT_SURVEY_MULTIPLE_SELECTION_QUESTIONS_DRAG_AND_DROP'
export const EDIT_SURVEY_ADD_MULTIPLE_SELECTION_QUESTIONS_ANSWER_OPTION = 'EDIT_SURVEY_ADD_MULTIPLE_SELECTION_QUESTIONS_ANSWER_OPTION'
export const EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_HEADING = 'EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_HEADING'
export const EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_QUESTION_LABEL = 'EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_QUESTION_LABEL'
export const EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_ANSWER_OPTION = 'EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_ANSWER_OPTION'
export const EDIT_SURVEY_DELETE_MULTIPLE_SELECTION_QUESTION_ANSWER = 'EDIT_SURVEY_DELETE_MULTIPLE_SELECTION_QUESTION_ANSWER'
export const EDIT_SURVEY_DELETE_MULTIPLE_SELECTION_QUESTION = 'EDIT_SURVEY_DELETE_MULTIPLE_SELECTION_QUESTION'
export const EDIT_SURVEY_ADD_MULTIPLE_SELECTION_QUESTION = 'EDIT_SURVEY_ADD_MULTIPLE_SELECTION_QUESTION'

// Storycard with high and low score comment questions

export const FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_START = 'FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_START';
export const FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_SUCCESS = 'FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_SUCCESS';
export const FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_FAILED = 'FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_FAILED';

export const FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_START = 'FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_START';
export const FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_SUCCESS = 'FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_SUCCESS';
export const FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_FAILED = 'FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_FAILED';

export const FETCH_MULTISELECT_QUESTION_START = 'FETCH_MULTISELECT_QUESTION_START';
export const FETCH_MULTISELECT_QUESTION_SUCCESS = 'FETCH_MULTISELECT_QUESTION_SUCCESS';
export const FETCH_MULTISELECT_QUESTION_FAIL = 'FETCH_MULTISELECT_QUESTION_FAIL';

export const FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_START = 'FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_START';
export const FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_SUCCESS = 'FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_SUCCESS';
export const FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_FAIL = 'FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_FAIL';

export const ADD_FOLLOW_UP_QUESTIONS = 'ADD_FOLLOW_UP_QUESTIONS';
export const DELETE_FOLLOW_UP_QUESTIONS = 'DELETE_FOLLOW_UP_QUESTIONS';
export const ON_BLUR_OPEN_ENDED_HEADING_FOLLOW_UP_QUESTIONS = 'ON_BLUR_OPEN_ENDED_HEADING_FOLLOW_UP_QUESTIONS';
export const ON_BLUR_OPEN_ENDED_DESCRIPTION_FOLLOW_UP_QUESTIONS = 'ON_BLUR_OPEN_ENDED_DESCRIPTION_FOLLOW_UP_QUESTIONS';

//fetchDemographics in answering page

export const FETCH_USER_DEMOGRAPHICS_DATA_START = "FETCH_USER_DEMOGRAPHICS_DATA_START";
export const FETCH_USER_DEMOGRAPHICS_DATA_SUCCESS = "FETCH_USER_DEMOGRAPHICS_DATA_SUCCESS";
export const FETCH_USER_DEMOGRAPHICS_DATA_FAIL = "FETCH_USER_DEMOGRAPHICS_DATA_FAIL"
export const ON_BLUR_MATRIX_FOLLOW_UP_QUESTIONS = 'ON_BLUR_MATRIX_FOLLOW_UP_QUESTIONS';
export const ON_BLUR_OPEN_ENDED_HEADING_FOLLOW_UP_QUESTIONS_LEVEL = 'ON_BLUR_OPEN_ENDED_HEADING_FOLLOW_UP_QUESTIONS_LEVEL';
export const ON_BLUR_OPEN_ENDED_DESCRIPTION_FOLLOW_UP_QUESTIONS_LEVEL = 'ON_BLUR_OPEN_ENDED_DESCRIPTION_FOLLOW_UP_QUESTIONS_LEVEL';
export const ON_CHANGE_FOLLOW_UP_MATRIX_QUESTION_SCALE = 'ON_CHANGE_FOLLOW_UP_MATRIX_QUESTION_SCALE';
export const ON_CHANGE_FOLLOW_UP_QUESTION_CONDITION = 'ON_CHANGE_FOLLOW_UP_QUESTION_CONDITION';

export const ADD_QUESTION_LEVEL_MATRIX_FOLLOW_UP_QUESTIONS = 'ADD_QUESTION_LEVEL_MATRIX_FOLLOW_UP_QUESTIONS';
export const ADD_QUESTION_LEVEL_OPEN_ENDED_FOLLOW_UP_QUESTIONS = 'ADD_QUESTION_LEVEL_OPEN_ENDED_FOLLOW_UP_QUESTIONS';
export const DELETE_QUESTION_LEVEL_FOLLOW_UP = 'DELETE_QUESTION_LEVEL_FOLLOW_UP';

export const FETCH_HRIS_FILTERS_START = 'FETCH_HRIS_FILTERS_START'
export const FETCH_HRIS_FILTERS_SUCCESS = 'FETCH_HRIS_FILTERS_SUCCESS'
export const FETCH_HRIS_FILTERS_FAIL = 'FETCH_HRIS_FILTERS_FAIL'

//Display conditional block for topics based on hris

export const ADD_CONDITIONAL_DISPLAY_TOPICS = 'ADD_CONDITIONAL_DISPLAY_TOPICS'
export const ON_CHANGE_DISPLAY_CONDITION = 'ON_CHANGE_DISPLAY_CONDITION'
export const DELETE_CONDITIONAL_DISPLAY_TOPICS = 'DELETE_CONDITIONAL_DISPLAY_TOPICS'

//FollowUpQuestionsData

export const FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_STARTED = 'FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_STARTED'
export const FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_SUCCESS = 'FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_SUCCESS'
export const FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_FAILED = 'FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_FAILED'

//newAnswering page constants with updated-antd carousel

export const NEW_FETCH_USER_DEMOGRAPHICS_DATA_START = "NEW_FETCH_USER_DEMOGRAPHICS_DATA_START";
export const NEW_FETCH_USER_DEMOGRAPHICS_DATA_SUCCESS = "NEW_FETCH_USER_DEMOGRAPHICS_DATA_SUCCESS";
export const NEW_FETCH_USER_DEMOGRAPHICS_DATA_FAIL = "NEW_FETCH_USER_DEMOGRAPHICS_DATA_FAIL"

export const FETCH_ATTENT_SURVEY_DATA_START = "FETCH_GET_ATTENT_SURVEY_DATA_START";
export const FETCH_ATTENT_SURVEY_DATA_SUCCESS = "FETCH_GET_ATTENT_SURVEY_DATA_SUCCESS";
export const FETCH_ATTENT_SURVEY_DATA_FAIL = "FETCH_GET_ATTENT_SURVEY_DATA_FAIL";

export const UPDATE_ANSWERS_IN_SURVEY_PAGE = "UPDATE_ANSWERS_IN_SURVEY_PAGE";

export const UPDATE_CURRENT_INDEX_OF_QUESTION = "UPDATE_CURRENT_INDEX_OF_QUESTION";

export const UPDATE_TEXT_ANSWER_IN_SURVEY = "UPDATE_TEXT_ANSWER_IN_SURVEY";

export const SURVEY_PAGE_TRANSLATION_START = "SURVEY_PAGE_TRANSLATION_START";
export const SURVEY_PAGE_TRANSLATION_FAIL = "SURVEY_PAGE_TRANSLATION_FAIL";
export const SURVEY_PAGE_TRANSLATION_SUCCESS = "SURVEY_PAGE_TRANSLATION_SUCCESS";

export const SUBMIT_SURVEY_RESPONSE_START = "SUBMIT_SURVEY_RESPONSE_START";
export const SUBMIT_SURVEY_RESPONSE_SUCCESS = "SUBMIT_SURVEY_RESPONSE_SUCCESS";
export const SUBMIT_SURVEY_RESPONSE_FAIL = "SUBMIT_SURVEY_RESPONSE_FAIL";

export const ON_CHANGE_TRANSLATION_LANGUAGE = "ON_CHANGE_TRANSLATION_LANGUAGE"

export const UPDATE_CURRENT_INDEX_DETAILS = "UPDATE_CURRENT_INDEX_DETAILS"

export const BACK_TO_FIRST_PAGE = "BACK_TO_FIRST_PAGE"
export const GENERATE_ANSWER_SET_SUCCESS = "GENERATE_ANSWER_SET_SUCCESS"
export const GENERATE_ANSWER_SET_FAIL = "GENERATE_ANSWER_SET_FAIL"
//manage trend pairing

export const FETCH_TREND_PAIRING_DATA_START = 'FETCH_TREND_PAIRING_DATA_START'
export const FETCH_TREND_PAIRING_DATA_SUCCESS = 'FETCH_TREND_PAIRING_DATA_SUCCESS'
export const FETCH_TREND_PAIRING_DATA_FAIL = 'FETCH_TREND_PAIRING_DATA_FAIL'

export const FETCH_COMPARISON_SURVEY_DATA_START = 'FETCH_COMPARISON_SURVEY_DATA_START'
export const FETCH_COMPARISON_SURVEY_DATA_SUCCESS = 'FETCH_COMPARISON_SURVEY_DATA_SUCCESS'
export const FETCH_COMPARISON_SURVEY_DATA_FAIL = 'FETCH_COMPARISON_SURVEY_DATA_FAIL'

export const UPDATE_TREND_DATA_SUCCESS = 'UPDATE_TREND_DATA_SUCCESS'
export const UPDATE_TREND_DATA_START = 'UPDATE_TREND_DATA_START'
export const UPDATE_TREND_DATA_FAIL = 'UPDATE_TREND_DATA_FAIL'

export const HANDLE_CHANGE_PAIRING_SURVEY_DROPDOWN = "HANDLE_CHANGE_PAIRING_SURVEY_DROPDOWN"
export const UPDATE_COMPARISON_SURVEY_DATA = 'UPDATE_COMPARISON_SURVEY_DATA'
export const HANDLE_ON_DRAG_END = "HANDLE_ON_DRAG_END"
export const HANDLE_DELETE_MATCHED_QUESTION = "HANDLE_DELETE_MATCHED_QUESTION"

export const TREND_AUTOMATCHING_START = "TREND_AUTOMATCHING_START"
export const GET_QUESTION_TABLE_TREND_START = 'GET_QUESTION_TABLE_TREND_START'
export const GET_QUESTION_TABLE_TREND_SUCCESS = 'GET_QUESTION_TABLE_TREND_SUCCESS'
export const GET_QUESTION_TABLE_TREND_FAIL = 'GET_QUESTION_TABLE_TREND_FAIL'


export const ON_CHANGE_TREND_TOGGLE = 'ON_CHANGE_TREND_TOGGLE'

export const FETCH_TREND_VISUALIZATION_DATA_START = 'FETCH_TREND_VISUALIZATION_DATA_START'
export const FETCH_TREND_VISUALIZATION_DATA_SUCCESS = 'FETCH_TREND_VISUALIZATION_DATA_SUCCESS'
export const FETCH_TREND_VISUALIZATION_DATA_FAIL = 'FETCH_TREND_VISUALIZATION_DATA_FAIL'

export const SURVEY_EDITOR_OPTIONS_DRAG_END = 'SURVEY_EDITOR_OPTIONS_DRAG_END'
export const SURVEY_EDITOR_OPTIONS_DELETE = "SURVEY_EDITOR_OPTIONS_DELETE"
export const SURVEY_EDITOR_OPTIONS_ADD = "SURVEY_EDITOR_OPTIONS_ADD"
export const SURVEY_EDITOR_HANDLE_CHANGE_OPTIONS = "SURVEY_EDITOR_HANDLE_CHANGE_OPTIONS"
export const SURVEY_EDITOR_HANDLE_MULTIOPTION_TOGGLE = "SURVEY_EDITOR_HANDLE_MULTIOPTION_TOGGLE"
export const SURVEY_EDITOR_HANDLE_TOGGLE_WRITE_IN_OPTION = "SURVEY_EDITOR_HANDLE_TOGGLE_WRITE_IN_OPTION"

export const SURVEY_EDITOR_LIKERT_DIMENSION_ORDESCRIPTION_CHANGE = 'SURVEY_EDITOR_LIKERT_DIMENSION_ORDESCRIPTION_CHANGE'
export const SURVEY_EDITOR_LIKERT_DRAG_AND_DROP = 'SURVEY_EDITOR_LIKERT_DRAG_AND_DROP'
export const SURVEY_EDITOR_LIKERT_REQUIRED_CHANGE = 'SURVEY_EDITOR_LIKERT_REQUIRED_CHANGE'
export const SURVEY_EDITOR_CHANGE_TOPIC ="SURVEY_EDITOR_CHANGE_TOPIC"
export const SURVEY_EDITOR_LIKERT_CHANGE_OUTCOME_VARIABLE = 'SURVEY_EDITOR_LIKERT_CHANGE_OUTCOME_VARIABLE'
export const SURVEY_EDITOR_LIKERT_CHANGE_NA_OPTION = 'SURVEY_EDITOR_LIKERT_CHANGE_NA_OPTION'
export const SURVEY_EDITOR_MATRIX_CHANGE_NA_OPTION = 'SURVEY_EDITOR_MATRIX_CHANGE_NA_OPTION'

export const SURVEY_EDITOR_LIKERT_CHANGE_OPTIONS_COUNT = 'SURVEY_EDITOR_LIKERT_CHANGE_OPTIONS_COUNT'

export const SURVEY_EDITOR_LIKERT_QUESTION_LABEL_CHANGE = 'SURVEY_EDITOR_LIKERT_QUESTION_LABEL_CHANGE'
export const SURVEY_EDITOR_LIKERT_QUESTION_ADD_FOLLOWUP = 'SURVEY_EDITOR_LIKERT_QUESTION_ADD_FOLLOWUP'
export const SURVEY_EDITOR_LIKERT_QUESTION_DELETE_QUESTION = 'SURVEY_EDITOR_LIKERT_QUESTION_DELETE_QUESTION'
export const SURVEY_EDITOR_LIKERT_QUESTION_ADD_QUESTION = 'SURVEY_EDITOR_LIKERT_QUESTION_ADD_QUESTION'

export const SURVEY_EDITOR_TEXT_ENTRY_CHANGE_QUESTION_AND_LABEL = 'SURVEY_EDITOR_TEXT_ENTRY_CHANGE_QUESTION_AND_LABEL'

export const SURVEY_EDITOR_LIKERT_QUESTION_DELETE_FOLLOWUP = 'SURVEY_EDITOR_LIKERT_QUESTION_DELETE_FOLLOWUP'
export const SURVEY_EDITOR_MULTIPLE_CHOICE_DELETE_FOLLOWUP = 'SURVEY_EDITOR_MULTIPLE_CHOICE_DELETE_FOLLOWUP'


export const SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_CONDITION = 'SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_CONDITION'
export const SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_LABEL = 'SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_LABEL'
export const SURVEY_EDITOR_MULTIPLE_CHOICE_CHANGE_FOLLOWUP_LABEL = 'SURVEY_EDITOR_MULTIPLE_CHOICE_CHANGE_FOLLOWUP_LABEL'

export const SURVEY_EDITOR_RATING_RIGHT_CHANGE_OPTION_COUNT_AND_SHAPE = 'SURVEY_EDITOR_RATING_RIGHT_CHANGE_OPTION_COUNT_AND_SHAPE'
export const SURVEY_EDITOR_RATING_RIGHT_CHANGE_QUESTION_AND_LABEL = 'SURVEY_EDITOR_RATING_RIGHT_CHANGE_QUESTION_AND_LABEL'

export const SURVEY_EDITOR_RANKING_RIGHT_CHANGE_QUESTION_AND_LABEL = 'SURVEY_EDITOR_RANKING_RIGHT_CHANGE_QUESTION_AND_LABEL'

export const SURVEY_EDITOR_RANKING_DRAG_AND_DROP = 'SURVEY_EDITOR_RANKING_DRAG_AND_DROP'
export const SURVEY_EDITOR_RANKING_DELETE_OR_ADD_OPTION = 'SURVEY_EDITOR_RANKING_DELETE_OR_ADD_OPTION'

export const SURVEY_EDITOR_MATRIX_CHANGE_LABELS = 'SURVEY_EDITOR_MATRIX_CHANGE_LABELS'

export const SURVEY_EDITOR_LIKERT_OPTION_CHANGE = 'SURVEY_EDITOR_LIKERT_OPTION_CHANGE'
export const SURVEY_EDITOR_LIKERT_OPTION_CHANGE_LABEL = 'SURVEY_EDITOR_LIKERT_OPTION_CHANGE_LABEL'

export const SURVEY_EDITOR_LIKERT_ADD_DISPLAY_CONDITION = 'SURVEY_EDITOR_LIKERT_ADD_DISPLAY_CONDITION'
export const SURVEY_EDITOR_LIKERT_CHANGE_DISPLAY_CONDITION = 'SURVEY_EDITOR_LIKERT_CHANGE_DISPLAY_CONDITION'
export const SURVEY_EDITOR_LIKERT_DELETE_DISPLAY_CONDITION = 'SURVEY_EDITOR_LIKERT_DELETE_DISPLAY_CONDITION'

export const SURVEY_EDITOR_ADD_QUESTIONS_FROM_LIBRARY = 'SURVEY_EDITOR_ADD_QUESTIONS_FROM_LIBRARY'

export const SURVEY_EDITOR_MATRIX_ADD_ROW_OR_COL = 'SURVEY_EDITOR_MATRIX_ADD_ROW_OR_COL'
export const SURVEY_EDITOR_MATRIX_REMOVE_ROW_OR_COL = 'SURVEY_EDITOR_MATRIX_REMOVE_ROW_OR_COL'

export const SURVEY_EDITOR_ADD_BLOCK = 'SURVEY_EDITOR_ADD_BLOCK'

export const SURVEY_EDITOR_HANDLE_CHANGE_QUESTION = 'SURVEY_EDITOR_HANDLE_CHANGE_QUESTION'
export const SURVEY_EDITOR_HANDLE_DELETE_BLOCK = 'SURVEY_EDITOR_HANDLE_DELETE_BLOCK'
export const SURVEY_EDITOR_HANDLE_CREATE_DUPLICATE = 'SURVEY_EDITOR_HANDLE_CREATE_DUPLICATE'
export const SURVEY_EDITOR_HANDLE_BLOCK_DRAG_END = 'SURVEY_EDITOR_HANDLE_BLOCK_DRAG_END'

export const SURVEY_EDITOR_HANDLE_ADD_FOLLOWUP_QUESTION = 'SURVEY_EDITOR_HANDLE_ADD_FOLLOWUP_QUESTION'
export const SURVEY_EDITOR_HANDLE_ADD_CONDITIONAL_FOLLOWUP = 'SURVEY_EDITOR_HANDLE_ADD_CONDITIONAL_FOLLOWUP'
export const SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_LABEL = 'SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_LABEL'
export const SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP = 'SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP'
export const SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_CHANGE = 'SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_CHANGE'

export const HANDLE_UPDATE_REDIRECT_VALUE_QUESTION = 'HANDLE_UPDATE_REDIRECT_VALUE_QUESTION'

export const HANDLE_CHANGE_RANKING = "HANDLE_CHANGE_RANKING";
export const HANDLE_CHANGE_RATING = "HANDLE_CHANGE_RATING";
export const HANDLE_CHANGE_TABLE_MATRIX = "HANDLE_CHANGE_TABLE_MATRIX";
export const HANDLE_CHANGE_NETPROMOTER = "HANDLE_CHANGE_NETPROMOTER";

export const SURVEY_EDITOR_ADD_IMAGES_FROM_LIBRARY = 'SURVEY_EDITOR_ADD_IMAGES_FROM_LIBRARY'
export const SURVEY_EDITOR_COPY_TO_ANSWERING_PAGE = 'SURVEY_EDITOR_COPY_TO_ANSWERING_PAGE'

export const SET_TOPICS_QUESTIONS_TREND = 'SET_TOPICS_QUESTIONS_TREND'

export const SURVEY_EDITOR_NET_PROMOTER_CHANGE_QUESTION_AND_LABEL = 'SURVEY_EDITOR_NET_PROMOTER_CHANGE_QUESTION_AND_LABEL'
export const SURVEY_EDITOR_DESCRIPTIVE_SECTION_CHANGE = 'SURVEY_EDITOR_DESCRIPTIVE_SECTION_CHANGE'

export const SET_DEMOGRAPHICS_TOPICS_QUESTIONS_TREND = 'SET_DEMOGRAPHICS_TOPICS_QUESTIONS_TREND'
export const SET_DEMOGRAPHICS_TREE_DATA_SELECT = 'SET_DEMOGRAPHICS_TREE_DATA_SELECT'

export const FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_START = 'FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_START'
export const FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_SUCCESS = 'FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_SUCCESS'
export const FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_FAIL = 'FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_FAIL'

export const FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_START = 'FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_START'
export const FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_SUCCESS = 'FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_SUCCESS'
export const FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_FAIL = 'FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_FAIL'


export const FETCH_RESULTS_PAGE_DATA_START = 'FETCH_RESULTS_PAGE_DATA_START'
export const FETCH_RESULTS_PAGE_DATA_SUCCCESS = 'FETCH_RESULTS_PAGE_DATA_SUCCESS'
export const FETCH_RESULTS_PAGE_DATA_FAIL = 'FETCH_RESULTS_PAGE_DATA_FAIL'

export const FETCH_STATUS_OF_MANAGER_HEATMAP_START = 'FETCH_STATUS_OF_MANAGER_HEATMAP_START'
export const FETCH_STATUS_OF_MANAGER_HEATMAP_SUCCESS = 'FETCH_STATUS_OF_MANAGER_HEATMAP_SUCCESS'
export const FETCH_STATUS_OF_MANAGER_HEATMAP_FAIL = 'FETCH_STATUS_OF_MANAGER_HEATMAP_FAIL'

//Feedback 360 

export const HANDLE_DRAG_END_PRIORITY = "HANDLE_DRAG_END_PRIORITY"
export const HANDLE_CHANGE_PRIORITY_TEXT = "HANDLE_CHANGE_PRIORITY_TEXT"
export const HANDLE_CHANGE_CHECKBOX_FEEDBACK_360 = "HANDLE_CHANGE_CHECKBOX_FEEDBACK_360"

export const FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_START = 'FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_START'
export const FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_SUCCCESS = 'FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_SUCCESS'
export const FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_FAIL = 'FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_FAIL'
export const BEHAVIOUR_DRAG_STRENGTH_IMPROVEMENT = 'BEHAVIOUR_DRAG_STRENGTH_IMPROVEMENT'
export const BEHAVIOUR_DRAG_DELETE ='BEHAVIOUR_DRAG_DELETE'


export const SET_SELECTED_COMPARISON = 'SET_SELECTED_COMPARISON';
export const SET_SELECTED_RANGE_SELECTOR = 'SET_SELECTED_RANGE_SELECTOR';
export const SET_SELECTED_PALETTE ="SET_SELECTED_PALETTE";
export const SET_SELECTED_DELTA_VALUE ="SET_SELECTED_DELTA_VALUE";
export const SET_SELECTED_THRESHOLD_VALUE ="SET_SELECTED_THRESHOLD_VALUE";
export const ADD_COMPONENT ="ADD_COMPONENT";
export const REMOVE_COMPONENT ="REMOVE_COMPONENT"



export const SAVE_HEATMAP_CONFIGURATION_SUCCESS = "SAVE_HEATMAP_CONFIGURATION_SUCCESS"
export const SAVE_HEATMAP_CONFIGURATION_FAIL ="SAVE_HEATMAP_CONFIGURATION_FAIL"
export const SAVE_HEATMAP_CONFIGURATION_START ="SAVE_HEATMAP_CONFIGURATION_START"

export const FILE_UPLOAD_STATUS_START="FILE_UPLOAD_STATUS_START"
export const FILE_UPLOAD_STATUS_SUCCESS ="FILE_UPLOAD_STATUS_SUCCESS"
export const FILE_UPLOAD_STATUS_FAIL = "FILE_UPLOAD_STATUS_FAIL"

//Trend Insights

export const FETCH_TREND_INSIGHTS_DATA_START = 'FETCH_TREND_INSIGHTS_DATA_START'
export const FETCH_TREND_INSIGHTS_DATA_SUCCESS = 'FETCH_TREND_INSIGHTS_DATA_SUCCESS'
export const FETCH_TREND_INSIGHTS_DATA_FAIL = 'FETCH_TREND_INSIGHTS_DATA_FAIL'

//Dashboard ppt download

export const EXPORT_DASHBOARD_PPT_START = 'DOWNLOAD_PPT_START'
export const EXPORT_DASHBOARD_PPT_SUCCESS = 'EXPORT_DASHBOARD_PPT_SUCCESS'
export const EXPORT_DASHBOARD_PPT_FAIL = 'EXPORT_DASHBOARD_PPT_FAIL'

//Dashoboard download status check 

export const EXPORT_PPT_STATUS_START='EXPORT_PPT_STATUS_START'
export const EXPORT_PPT_STATUS_SUCCESS = 'EXPORT_PPT_STATUS_SUCCESS'
export const EXPORT_PPT_STATUS_FAIL = 'EXPORT_PPT_STATUS_FAIL'

//list cohortsurvey


export const FETCH_COHORT_SURVEY_DATA_START = 'FETCH_COHORT_SURVEY_DATA_START'
export const FETCH_COHORT_SURVEY_DATA_SUCCESS = 'FETCH_COHORT_SURVEY_DATA_SUCCESS'
export const FETCH_COHORT_SURVEY_DATA_FAIL = 'FETCH_COHORT_SURVEY_DATA_FAIL'

//List child surveys under the cohort survey

export const FETCH_CHILD_COHORT_SURVEY_DATA_START = 'FETCH_CHILD_COHORT_SURVEY_DATA_START'
export const FETCH_CHILD_COHORT_SURVEY_DATA_SUCCESS = 'FETCH_CHILD_COHORT_SURVEY_DATA_SUCCESS'
export const FETCH_CHILD_COHORT_SURVEY_DATA_FAIL = 'FETCH_CHILD_COHORT_SURVEY_DATA_FAIL'

//Fetch child cohort for survey wizard

export const FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_START = 'FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_START'
export const FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_SUCCESS = 'FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_SUCCESS'
export const FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_FAIL = 'FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_FAIL'

//change the table in cohort survey

export const SET_SORT_ORDER_AND_SORT_FIELD_FOR_COHORT = 'SET_SORT_ORDER_AND_SORT_FIELD_FOR_COHORT'

//fetch cohort survey parent listing

export const FETCH_COHORT_PARENT_LIST_STARTED = "FETCH_COHORT_PARENT_LIST_STARTED"
export const FETCH_COHORT_PARENT_LIST_SUCCESS = "FETCH_COHORT_PARENT_LIST_SUCCESS"
export const FETCH_COHORT_PARENT_LIST_FAILED = "FETCH_COHORT_PARENT_LIST_FAILED"

//set page number for cohort listing

export const SET_PAGE_NUMBER_FOR_COHORT_SURVEY_PAGE = 'SET_PAGE_NUMBER_FOR_COHORT_SURVEY_PAGE'

export const FETCH_FEEDBACK_PROJECT_DETAILS_START = 'FETCH_FEEDBACK_PROJECT_DETAILS_START'
export const FETCH_FEEDBACK_PROJECT_DETAILS_SUCCESS = 'FETCH_FEEDBACK_PROJECT_DETAILS_SUCCESS'
export const FETCH_FEEDBACK_PROJECT_DETAILS_FAIL = 'FETCH_FEEDBACK_PROJECT_DETAILS_FAIL'


//Access tabs in project listing

export const FETCH_TAB_CONFIGURATION_IN_PROJECTS_START = 'FETCH_TAB_CONFIGURATION_IN_PROJECTS_START'
export const FETCH_TAB_CONFIGURATION_IN_PROJECTS_SUCCESS = 'FETCH_TAB_CONFIGURATION_IN_PROJECTS_SUCCESS'
export const FETCH_TAB_CONFIGURATION_IN_PROJECTS_FAIL = 'FETCH_TAB_CONFIGURATION_IN_PROJECTS_FAIL'


export const FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_START = 'FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_START'
export const FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_SUCCCESS = 'FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_SUCCESS'
export const FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_FAIL = 'FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_FAIL'

export const FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_START = 'FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_START'
export const FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_SUCCESS = 'FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_SUCCESS'
export const FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_FAIL = 'FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_FAIL'

export const FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_FAIL = 'FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_FAIL';
export const FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_SUCCESS = 'FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_SUCCESS';
export const FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_START = 'FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_START'

export const GO_TO_PARTICULAR_PAGE = "GO_TO_PARTICULAR_PAGE"
export const PROCEED_TO_NEXT_STEP = "PROCEED_TO_NEXT_STEP"

export const GET_NORM_BENCHMARK_VALUE_SUCCESS = 'GET_NORM_BENCHMARK_VALUE_SUCCESS';
export const GET_NORM_BENCHMARK_VALUE_FAILED = 'GET_NORM_BENCHMARK_FAILED';
export const GET_NORM_BENCHMARK_VALUE_STARTED = ' GET_NORM_BENCHMARK_VALUE_STARTED';

export const SET_NORM_BENCHMARK_VALUE_SUCCESS = 'SET_NORM_BENCHMARK_VALUE_SUCCESS';
export const SET_NORM_BENCHMARK_VALUE_FAILED = 'SET_NORM_BENCHMARK_FAILED';
export const SET_NORM_BENCHMARK_VALUE_STARTED = ' SET_NORM_BENCHMARK_VALUE_STARTED';


export const HANDLE_CHANGE_BENCHMARK_NORMS = 'HANDLE_CHANGE_BENCHMARK_NORMS'


export const FETCH_STATUS_REPORT_DATA_START = 'FETCH_STATUS_REPORT_DATA_START'
export const FETCH_STATUS_REPORT_DATA_SUCCESS = 'FETCH_STATUS_REPORT_DATA_SUCCESS'
export const FETCH_STATUS_REPORT_DATA_FAIL = 'FETCH_STATUS_REPORT_DATA_FAIL'

export const SURVEY_EDITOR_MATRIX_SUBCATEGORY_GENERATION = 'SURVEY_EDITOR_MATRIX_SUBCATEGORY_GENERATION'
export const SURVEY_EDITOR_MATRIX_ADD_SUBCATEGORY = 'SURVEY_EDITOR_MATRIX_ADD_SUBCATEGORY'
export const SURVEY_EDITOR_MATRIX_EDIT_SUBCATEGORY_IN_QUESTIONS = 'SURVEY_EDITOR_MATRIX_EDIT_SUBCATEGORY_IN_QUESTIONS'

export const SURVEY_EDITOR_MATRIX_DELETE_SUBCATEGORY = 'SURVEY_EDITOR_MATRIX_DELETE_SUBCATEGORY'

export const SURVEY_EDITOR_MATRIX_CHANGE_SUBCATEGORY = 'SURVEY_EDITOR_MATRIX_CHANGE_SUBCATEGORY'

//summarization actions

export const GENERATE_TEXT_SUMMARIZATION_DATA_START = 'GENERATE_TEXT_SUMMARIZATION_DATA_START'
export const GENERATE_TEXT_SUMMARIZATION_DATA_SUCCESS = 'GENERATE_TEXT_SUMMARIZATION_DATA_SUCCESS'
export const GENERATE_TEXT_SUMMARIZATION_DATA_FAIL = 'GENERATE_TEXT_SUMMARIZATION_DATA_FAIL'
