import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const cultureOfInclusionDirector = {
    "blockId": uuidv4(),
    "description": "<p>Enable everyone to do their best work.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Enables Psychological Safety"),
                    "subCategory": "Enables Psychological Safety",
                    "staticQuestionId": generateStaticQuestionId("Treats all people with respect, regardless of their position or background"),
                    "HTMLText": "<p>Treats all people with respect, regardless of their position or background</p>",
                    "isSelected": true,
                    "text": "Treats all people with respect, regardless of their position or background",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Enables Psychological Safety"),
                    "subCategory": "Enables Psychological Safety",
                    "staticQuestionId": generateStaticQuestionId("Creates inclusion by seeking diverse perspectives and experiences"),
                    "HTMLText": "<p>Creates inclusion by seeking diverse perspectives and experiences</p>",
                    "isSelected": true,
                    "text": "Creates inclusion by seeking diverse perspectives and experiences",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Enables Psychological Safety"),
                    "subCategory": "Enables Psychological Safety",
                    "staticQuestionId": generateStaticQuestionId("Fosters a climate of open and candid dialogues"),
                    "HTMLText": "<p>Fosters a climate of open and candid dialogues</p>",
                    "isSelected": true,
                    "text": "Fosters a climate of open and candid dialogues",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Builds Diverse Teams"),
                    "subCategory": "Builds Diverse Teams",
                    "staticQuestionId": generateStaticQuestionId("Challenges own and others' biases and beliefs when making decisions"),
                    "HTMLText": "<p>Challenges own and others' biases and beliefs when making decisions</p>",
                    "isSelected": true,
                    "text": "Challenges own and others' biases and beliefs when making decisions",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Builds Diverse Teams"),
                    "subCategory": "Builds Diverse Teams",
                    "staticQuestionId": generateStaticQuestionId("Uses diverse networks to drive high performance"),
                    "HTMLText": "<p>Uses diverse networks to drive high performance</p>",
                    "isSelected": true,
                    "text": "Uses diverse networks to drive high performance",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Builds Diverse Teams"),
                    "subCategory": "Builds Diverse Teams",
                    "staticQuestionId": generateStaticQuestionId("Works well with people who differ in race, gender, culture, generation, or age"),
                    "HTMLText": "<p>Works well with people who differ in race, gender, culture, generation, or age</p>",
                    "isSelected": true,
                    "text": "Works well with people who differ in race, gender, culture, generation, or age",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adapts to Cultural Contexts"),
                    "subCategory": "Adapts to Cultural Contexts",
                    "staticQuestionId": generateStaticQuestionId("Seeks opportunities to learn about different cultures, value systems, and beliefs"),
                    "HTMLText": "<p>Seeks opportunities to learn about different cultures, value systems, and beliefs</p>",
                    "isSelected": true,
                    "text": "Seeks opportunities to learn about different cultures, value systems, and beliefs",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adapts to Cultural Contexts"),
                    "subCategory": "Adapts to Cultural Contexts",
                    "staticQuestionId": generateStaticQuestionId("Adapts behaviors based on cultural context: has knowledge and acts on it"),
                    "HTMLText": "<p>Adapts behaviors based on cultural context: has knowledge and acts on it</p>",
                    "isSelected": true,
                    "text": "Adapts behaviors based on cultural context: has knowledge and acts on it",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adapts to Cultural Contexts"),
                    "subCategory": "Adapts to Cultural Contexts",
                    "staticQuestionId": generateStaticQuestionId("Challenges biases and beliefs when making decisions"),
                    "HTMLText": "<p>Challenges biases and beliefs when making decisions</p>",
                    "isSelected": true,
                    "text": "Challenges biases and beliefs when making decisions",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Inclusion"
}