import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const teamDevelopmentDirector = {
    "blockId": uuidv4(),
    "description": "<p>Grow your team: Optimize teams' potential, capacity, and performance by building high performing collaborative teams that are inspired and connected to the bigger picture, accountable, driven, and inclusive.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Establishes Purpose"),
                    "subCategory": "Establishes Purpose",
                    "staticQuestionId": generateStaticQuestionId("Demonstartes an understanding and appreciation of Applied's vision and enterprise aspirations"),
                    "HTMLText": "<p>Demonstartes an understanding and appreciation of Applied's vision and enterprise aspirations</p>",
                    "isSelected": true,
                    "text": "Demonstartes an understanding and appreciation of Applied's vision and enterprise aspirations",
                    "value": uuidv4()
                },
                // {
                //     "staticQuestionId": generateStaticQuestionId("Assigns clear accountability for objectives."),
                //     "HTMLText": "<p>Assigns clear accountability for objectives.</p>",
                //     "isSelected": true,
                //     "text": "Assigns clear accountability for objectives.",
                //     "value": uuidv4()
                // },
                {
                    "subCategoryId": generateStaticQuestionId("Establishes Purpose"),
                    "subCategory": "Establishes Purpose",
                    "staticQuestionId": generateStaticQuestionId("Communicates how operational goals link to strategic objectives"),
                    "HTMLText": "<p>Communicates how operational goals link to strategic objectives</p>",
                    "isSelected": true,
                    "text": "Communicates how operational goals link to strategic objectives",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Establishes Purpose"),
                    "subCategory": "Establishes Purpose",
                    "staticQuestionId": generateStaticQuestionId("Prioritizes relentlessly and communicates with clarity to all concerned"),
                    "HTMLText": "<p>Prioritizes relentlessly and communicates with clarity to all concerned</p>",
                    "isSelected": true,
                    "text": "Prioritizes relentlessly and communicates with clarity to all concerned",
                    "value": uuidv4()
                },

                
                {
                    "subCategoryId": generateStaticQuestionId("Organizes for Impact"),
                    "subCategory": "Organizes for Impact",
                    "staticQuestionId": generateStaticQuestionId("Ensures the team has the resources needed to successfully achieve its objectives"),
                    "HTMLText": "<p>Ensures the team has the resources needed to successfully achieve its objectives</p>",
                    "isSelected": true,
                    "text": "Ensures the team has the resources needed to successfully achieve its objectives",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Organizes for Impact"),
                    "subCategory": "Organizes for Impact",
                    "staticQuestionId": generateStaticQuestionId("Anticipates changes and provides mid-course correction to manage emerging realities & future needs"),
                    "HTMLText": "<p>Anticipates changes and provides mid-course correction to manage emerging realities & future needs</p>",
                    "isSelected": true,
                    "text": "Anticipates changes and provides mid-course correction to manage emerging realities & future needs",
                    "value": uuidv4()
                },

{
    "subCategoryId": generateStaticQuestionId("Organizes for Impact"),
    "subCategory": "Organizes for Impact",
    "staticQuestionId": generateStaticQuestionId("Shows trust in team by delegating work and decisions, as appropriate"),
    "HTMLText": "<p>Shows trust in team by delegating work and decisions, as appropriate</p>",
                    "isSelected": true,
    "text": "Shows trust in team by delegating work and decisions, as appropriate",
                    "value": uuidv4()
                },


                {
                    "subCategoryId": generateStaticQuestionId("Drives Accountability"),
                    "subCategory": "Drives Accountability",
                    "staticQuestionId": generateStaticQuestionId("Holds the team accountable for achieving results"),
                    "HTMLText": "<p>Holds the team accountable for achieving results</p>",
                    "isSelected": true,
                    "text": "Holds the team accountable for achieving results",
                    "value": uuidv4()
                },
                 
                {
                    "subCategoryId": generateStaticQuestionId("Drives Accountability"),
                    "subCategory": "Drives Accountability",
                    "staticQuestionId": generateStaticQuestionId(" Ensures mechanisms for the team to self-monitor performance and identify performance issues"),
                    "HTMLText": "<p> Ensures mechanisms for the team to self-monitor performance and identify performance issues</p>",
                    "isSelected": true,
                    "text": " Ensures mechanisms for the team to self-monitor performance and identify performance issues",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Drives Accountability"),
                    "subCategory": "Drives Accountability",
                    "staticQuestionId": generateStaticQuestionId("Challenges the status quo by identifying bold actions for implementation"),
                    "HTMLText": "<p>Challenges the status quo by identifying bold actions for implementation</p>",
                    "isSelected": true,
                    "text": "Challenges the status quo by identifying bold actions for implementation",
                    "value": uuidv4()
                },




            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "High Performance Team"
}